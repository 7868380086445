import { configureStore } from "@reduxjs/toolkit"
import { useDispatch, useSelector, type TypedUseSelectorHook } from "react-redux"
import { combineReducers } from "redux"
import { createLogger } from "redux-logger"
import { checkIfloggingOn } from "../utils"
import { datasetsApi } from "./queries/dataset.queries"
import { catalogReducer } from "./reducers/catalogReducer"
import { datasetsReducer } from "./reducers/datasetsReducer"

// only for redux logging
const isLoggingOn = checkIfloggingOn()

const rootReducer = combineReducers({
  datasetsReducer,
  catalogReducer,
  [datasetsApi.reducerPath]: datasetsApi.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    const updatedMiddleware = getDefaultMiddleware().concat(datasetsApi.middleware)
    if (!isLoggingOn) return updatedMiddleware
    return updatedMiddleware.concat(createLogger({ collapsed: true }))
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
