import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import React from "react"
import CustomAlert from "../../../../../../../../components/CustomAlert"
import { Loader } from "../../../../../../../../components/Loader/Loader"
import { StyledButton } from "../../../../../../../../components/StyledButton"
import { AccessRoleId } from "../../../../../../../../utils/api/AccessManagement/resourceAccess.types"
import { useResourceUsers } from "../../../../../../../../utils/auth/userHooks"
import type { DataEntity } from "../../../../../../../../utils/frontendTypes/dataEntity.types"
import { useAppSelector } from "../../../../../../../../utils/redux/store"
import { getRtkQueryErrorMessage } from "../../../../../../../../utils/utils"
import ResourceUsers from "./components/ResourceUser/ResourceUsers"
import UserNotFoundError from "./components/UserNotFoundError"

type Props = {
  open: boolean
  closeFn: () => void
  infoText?: string
  dataEntity: DataEntity
}

const ManageAccessModal = (p: Props) => {
  const { resourceUsers, isFetching, error } = useResourceUsers({ skip: !p.open, resourceUUID: p.dataEntity.uuid })
  const { viewers, editors, admins } = resourceUsers
  const lowerLevelError = useAppSelector(state => state.datasetsReducer.accessManagerError)

  const specialUserError = lowerLevelError && getRtkQueryErrorMessage(lowerLevelError) === "User not found"

  return (
    <Dialog open={p.open} onClose={p.closeFn} fullWidth maxWidth="lg" PaperProps={{ sx: { p: 3 } }}>
      <DialogTitle fontWeight={700} sx={{ mb: { xs: 3, lg: 1 } }}>
        Manage Access
      </DialogTitle>
      <DialogContent>
        {p.infoText && <Typography sx={{ mb: 3 }}>{p.infoText}</Typography>}

        {isFetching && (
          <Box width={"100%"} display="flex" justifyContent={"center"} sx={{ py: 4 }}>
            <Loader />
          </Box>
        )}
        {error && <CustomAlert error={error} />}
        {lowerLevelError && !specialUserError && <CustomAlert error={lowerLevelError} sx={{ mb: 1 }} />}
        {specialUserError && <UserNotFoundError />}
        {!error && !isFetching && (
          <Grid container direction={"row"} spacing={5}>
            <Grid item lg={4}>
              <ResourceUsers
                name="Viewers"
                description="Users in this group have read-only access to the dataset. They can view and explore the data but cannot make any changes."
                noUsersText="No viewers found. Assign viewers to let others view the dataset."
                users={viewers}
                roleId={AccessRoleId.Viewer}
                addButtonText="Add Viewer"
                resourceUuid={p.dataEntity.uuid}
              />
            </Grid>
            <Grid item lg={4}>
              <ResourceUsers
                name="Editors"
                description="Users in this group have full access to the dataset. They can view, modify, and update the data as needed."
                noUsersText="No editors found. Assign editors to manage content updates."
                users={editors}
                roleId={AccessRoleId.Editor}
                addButtonText="Add Editor"
                resourceUuid={p.dataEntity.uuid}
              />
            </Grid>
            <Grid item lg={4}>
              <ResourceUsers
                name="Admins"
                description="Users in this group have full control over the dataset and its settings. They can view, edit and manage permissions."
                users={admins}
                roleId={AccessRoleId.Admin}
                addButtonText="Add Admin"
                resourceUuid={p.dataEntity.uuid}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center", pl: 3 }}>
        <StyledButton
          variant="outlined"
          onClick={() => {
            p.closeFn()
          }}>
          Close
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ManageAccessModal)
