import downloadjs from "downloadjs"
import { type RawFileGeolocationFilter, type RawFileInfoGet } from "../../../../../../../utils/api/raw.types"
import { jsSDK } from "../../../../../../../utils/sdk/jsSDK"

export const createFileId = (fileInfo: RawFileInfoGet) => `${fileInfo.name}_${fileInfo.created_time}`

export const downloadRawFile = async (datasetNameId: string, fileName: string) => {
  try {
    const response = await jsSDK.datasets.getRawFileByDatasetNameID({ datasetNameId, fileName })
    try {
      const blob = await response.data.blob()
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      downloadjs(blob, fileName)
    } catch (e) {
      console.log(e)
    }
  } catch (e) {
    console.log(e)
  }
}

export const prepareOpenedFile = (file: File): File => {
  if (file.type === "" && file.name.endsWith(".nc")) {
    // Create a new File object with a manually set MIME type
    return new File([file], file.name, { type: "application/x-netcdf" })
  }
  return file
}

export const createRawFileFilter = (geometry: GeoJSON.Polygon): RawFileGeolocationFilter => {
  return {
    shape: geometry,
    operator: "intersects",
  }
}
