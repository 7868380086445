import { skipToken } from "@reduxjs/toolkit/query"
import { useSearchQueryArgs } from "../../pages/Catalog/components/CatalogResults/catalogResults.helpers"
import type { DataCollection } from "../frontendTypes/dataCollection.types"
import {
  useGetSharedCollectionsQuery,
  useGetSharedDatasetsInCollectionQuery,
  useGetSharedDatasetsQuery,
} from "../redux/queries/access.queries"
import {
  useGetDataCollectionSearchResultsQuery,
  useGetDatasetSearchResultsQuery,
} from "../redux/queries/catalog.queries"
import { useGetCollectionInternalDatasetsQuery } from "../redux/queries/dataset.queries"
import { ResourceTypeOdp } from "../sdk/utils/entities/sdk.resource.types"

/**
 * @param isPublic if set to true, will only return collections with label public
 * @param isInternallyShared if set to true, will only return collections with label internally shared,
 * if set to false, will only return collections without label internally shared or with it's value set to false
 * if undefined with ignore the label internally shared
 */
export const useCollections = ({
  isPublic,
  isInternallyShared,
}: {
  isPublic: boolean
  isInternallyShared?: boolean
}) => {
  const {
    queryArgs,
    isLoading: isQueryParamsLoading,
    noPossibleResults,
    isFetching: isQueryParamsFetching,
  } = useSearchQueryArgs(ResourceTypeOdp.Collection, isPublic)

  const shouldSkip = isQueryParamsLoading || noPossibleResults
  const collectionHookResults = useGetDataCollectionSearchResultsQuery(
    shouldSkip ? skipToken : { ...queryArgs, isPublic, isInternallyShared },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  const {
    data: sharedCollections,
    error: sharedError,
    isFetching: sharedFetching,
  } = useGetSharedCollectionsQuery(isInternallyShared ? skipToken : undefined)

  const collections = collectionHookResults.data

  const errors = []
  if (sharedError) errors.push(sharedError)
  if (collectionHookResults.error) errors.push(collectionHookResults.error)

  const isFetching = collectionHookResults.isFetching || isQueryParamsFetching || sharedFetching

  return { collections, sharedCollections, isFetching, noPossibleResults, errors }
}

/**
 * @param isPublic if set to true, will only return datasets with label public
 * @param isInternallyShared if set to true, will only return datasets with label internally shared,
 * if set to false, will only return datasets without label internally shared or with it's value set to false
 * if undefined with ignore the label internally shared
 */
export const useDatasets = ({ isPublic, isInternallyShared }: { isPublic: boolean; isInternallyShared?: boolean }) => {
  const {
    queryArgs,
    isLoading: isQueryParamsLoading,
    noPossibleResults,
    isFetching: isQueryParamsFetching,
  } = useSearchQueryArgs(ResourceTypeOdp.Dataset, isPublic)

  const shouldSkip = isQueryParamsLoading || noPossibleResults
  const getDatasetsHookResults = useGetDatasetSearchResultsQuery(
    shouldSkip ? skipToken : { ...queryArgs, isPublic, isInternallyShared },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  const {
    data: sharedDatasets,
    error: sharedError,
    isFetching: sharedFetching,
  } = useGetSharedDatasetsQuery(isInternallyShared ? skipToken : undefined)

  const errors = []
  if (sharedError) errors.push(sharedError)
  if (getDatasetsHookResults.error) errors.push(getDatasetsHookResults.error)

  const { data: datasets, isFetching: isFetchingDatasets } = getDatasetsHookResults

  const isFetching = isFetchingDatasets || isQueryParamsFetching || sharedFetching

  return { datasets, sharedDatasets, isFetching, noPossibleResults, errors }
}

export const useSharedCollectionDatasets = (collection: DataCollection) => {
  const {
    data: datasets,
    isFetching,
    error,
  } = useGetCollectionInternalDatasetsQuery(collection.nameId, { refetchOnMountOrArgChange: true })

  const {
    data: sharedDatasets,
    isFetching: sharedFetching,
    error: sharedError,
  } = useGetSharedDatasetsInCollectionQuery(collection.uuid)

  return {
    data: [...(datasets ?? []), ...(sharedDatasets ?? [])],
    isFetching: isFetching || sharedFetching,
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    error: error || sharedError,
  }
}
