import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import GeolocManualInputs from "../../pages/Catalog/components/CatalogRefineLocation/components/GeolocManualInputs"
import MapWithDraw from "../../pages/Catalog/components/CatalogRefineLocation/components/MapWithDraw"
import type { BoundingMapboxCoords } from "../../utils/frontendTypes/datasets.types"
import { StyledButton } from "../StyledButton"

type Props = {
  open: boolean
  closeFn: () => void
  actionButtonProps: {
    onClick: (geometry: GeoJSON.Polygon) => void
    text: string
  }
}

const RefineLocationMapModal = (p: Props) => {
  const [drawnGeometry, setDrawnGeometry] = useState<GeoJSON.Polygon>()
  const [numericCoords, setNumericCoords] = useState<BoundingMapboxCoords>()

  const actionClick = () => {
    if (drawnGeometry) p.actionButtonProps.onClick(drawnGeometry)
    p.closeFn()
  }

  const closeDialog = useCallback(() => {
    p.closeFn()
    setNumericCoords([])
  }, [p])

  useEffect(() => {
    if (p.open) return
    setDrawnGeometry(undefined)
    setNumericCoords(undefined)
  }, [p.open])

  return (
    <>
      <Dialog open={p.open} onClose={closeDialog} fullWidth maxWidth="lg" PaperProps={{ sx: { p: 3 } }}>
        <DialogTitle id="refine-location" fontWeight={700}>
          {"Refine location"}
        </DialogTitle>
        <DialogContent>
          {p.open && <GeolocManualInputs startingGeometry={drawnGeometry} clickOverrideCoords={setNumericCoords} />}
          {p.open && <MapWithDraw setDrawnGeometry={setDrawnGeometry} numericCoords={numericCoords} />}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <StyledButton onClick={actionClick} size="large" variant="contained">
            {p.actionButtonProps.text}
          </StyledButton>
          <StyledButton onClick={closeDialog} size="large">
            Cancel
          </StyledButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default React.memo(RefineLocationMapModal)
