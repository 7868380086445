import { FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { useDeleteResourceMutation } from "../../../utils/redux/queries/dataset.queries"
import CustomAlert from "../../CustomAlert"
import { Loader } from "../../Loader/Loader"

type Props = {
  resourceUuid: string
  type: "Dataset" | "DataCollection"
  redirectUrl?: string
  show: boolean
}

const DeleteConfirmInput = (p: Props) => {
  const [inputText, setInputText] = useState("")

  const [apiDelete, { isLoading: deleteInProgress, error }] = useDeleteResourceMutation()
  const nav = useNavigate()

  const deleteFn = useCallback(async () => {
    const res = (await apiDelete({ resourceId: p.resourceUuid, type: p.type })) as any
    if (!res.error) {
      nav(p.redirectUrl ?? "/")
    }
  }, [apiDelete, nav, p.resourceUuid, p.type, p.redirectUrl])

  useEffect(() => {
    if (inputText === "DELETE") void deleteFn()
  }, [inputText, p, deleteFn])

  const labelText = `Type DELETE to delete this ${p.type === "Dataset" ? "dataset" : "data collection"}.`

  const endAdornment = (
    <InputAdornment position="end">
      <Loader size={25} />
    </InputAdornment>
  )

  return (
    <>
      {p.show && (
        <FormControl variant="outlined" sx={{ mt: 4 }} fullWidth>
          <InputLabel htmlFor="delete-confirm-input">{labelText}</InputLabel>
          <OutlinedInput
            onChange={e => setInputText(e.target.value)}
            id="delete-confirm-input"
            endAdornment={deleteInProgress ? endAdornment : undefined}
            label={labelText}
            inputProps={{
              "data-testid": `delete${p.type}ConfirmInput`,
            }}
          />
        </FormControl>
      )}
      {error && <CustomAlert error={error} sx={{ mt: 2 }} />}
    </>
  )
}

export default React.memo(DeleteConfirmInput)
