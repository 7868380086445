import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import type { MetadataFilters } from "../queries/filtering.helpers"

const initialState: MetadataFilters = { searchString: "" }

const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setCatalogSearchString(state, action: PayloadAction<string>) {
      state.searchString = action.payload
    },
    setYearsFilter(state, action: PayloadAction<{ startYear?: number; endYear?: number }>) {
      state.startYear = action.payload.startYear
      state.endYear = action.payload.endYear
    },
    setBounidingBoxFilterRA(state, action: PayloadAction<GeoJSON.Polygon | undefined>) {
      state.boundingBox = action.payload
    },
  },
})

export const { setCatalogSearchString, setYearsFilter, setBounidingBoxFilterRA } = catalogSlice.actions
export const { reducer: catalogReducer } = catalogSlice
