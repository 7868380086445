import MapboxDraw from "@mapbox/mapbox-gl-draw"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
import _ from "lodash"
import mapboxgl, { type IControl, type MapEvent } from "mapbox-gl"
import { useEffect, useState } from "react"
import { colorPalette } from "../../../../styling/theme"
import { type BoundingMapboxCoords } from "../../../../utils/frontendTypes/datasets.types"
import { predefinedMaps } from "../../../../utils/hooks/useMiniMap"

export const readCoordinatesFromPolygon = (polygon: GeoJSON.Polygon): BoundingMapboxCoords => {
  return polygon.coordinates[0].map(([coord1, coord2]) => [_.round(coord1, 4), _.round(coord2, 4)])
}

type MapboxFeature = {
  geometry: GeoJSON.Polygon
  properties: Record<string, any>
  type: string
  id: string
}

export const useMapWithDraw = (boundingBox: number[] | undefined, containerName: string) => {
  const [drawnGeometry, setDrawnGeometry] = useState<GeoJSON.Polygon | undefined>()
  const [draw, setDraw] = useState<MapboxDraw | null>(null)

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: containerName,
      zoom: 0,
      style: predefinedMaps.standardBlue.setup.style,
      projection: predefinedMaps.standardBlue.setup.projection,
      bounds: new mapboxgl.LngLatBounds(new mapboxgl.LngLat(-180, 90), new mapboxgl.LngLat(180, 90)),
      interactive: true,
      accessToken:
        "pk.eyJ1Ijoib2NlYW5kYXRhZm91bmRhdGlvbiIsImEiOiJjazk5bGxpNWkwYWU1M2Vya3hkcHh4czdrIn0.yf7kIiPfDNE7KP9_9wTN6A",
    })

    map.on("load", () => {
      const newDraw = new MapboxDraw({
        controls: {
          point: false,
          line_string: false,
          combine_features: false,
          uncombine_features: false,
        },
        styles: styling,
      })

      map.addControl(newDraw as IControl, "top-left")

      newDraw.changeMode("draw_polygon")
      map.on("draw.create" as MapEvent, (e: { features: MapboxFeature[] }) => {
        setDrawnGeometry(e.features[0].geometry)
      })
      map.on(
        "draw.update" as MapEvent,
        function (e: {
          features: MapboxFeature[] // Array of features that were updated
          action: string // Name of the action that triggered the update
        }) {
          setDrawnGeometry(e.features[0].geometry)
        }
      )
      map.on("draw.delete" as MapEvent, () => {
        setDrawnGeometry(undefined) // empty [] because undefined gets neglected in other part of code
      })
      setDraw(newDraw)
    })
  }, [boundingBox, containerName])

  return { drawnGeometry, draw }
}

export const toMapboxCoordinates = (coords: number[]) => {
  const [longStart, latStart, longEnd, latEnd] = coords
  return [
    [longStart, latStart],
    [longEnd, latStart],
    [longEnd, latEnd],
    [longStart, latEnd],
    [longStart, latStart],
  ]
}

const lineColor = colorPalette.luminousGreen
const circleColor = colorPalette.luminousGreen

const styling = [
  // ACTIVE (being drawn)
  // line stroke
  {
    id: "gl-draw-line",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": lineColor,
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  // polygon fill
  {
    id: "gl-draw-polygon-fill",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    paint: {
      "fill-color": lineColor,
      "fill-outline-color": lineColor,
      "fill-opacity": 0.2,
    },
  },
  // polygon mid points
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 3,
      "circle-color": circleColor,
    },
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": lineColor,
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  // vertex point halos
  {
    id: "gl-draw-polygon-and-line-vertex-halo-active",
    type: "circle",
    filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    paint: {
      "circle-radius": 5,
      "circle-color": circleColor,
    },
  },
  // vertex points
  {
    id: "gl-draw-polygon-and-line-vertex-active",
    type: "circle",
    filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    paint: {
      "circle-radius": 3,
      "circle-color": circleColor,
    },
  },

  // INACTIVE (static, already drawn)
  // line stroke
  {
    id: "gl-draw-line-static",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": lineColor,
      "line-width": 3,
    },
  },
  // polygon fill
  {
    id: "gl-draw-polygon-fill-static",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
    paint: {
      "fill-color": lineColor,
      "fill-outline-color": lineColor,
      "fill-opacity": 0.1,
    },
  },
  // polygon outline
  {
    id: "gl-draw-polygon-stroke-static",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": lineColor,
      "line-width": 3,
    },
  },
]
