import { Grid } from "@mui/material"
import React from "react"
import { useResourceAccess } from "../../../../../../utils/auth/userHooks"
import { type DataSet } from "../../../../../../utils/frontendTypes/datasets.types"
import FileToUploadRaw from "./components/FileToUploadRaw"
import UploadedFilesRaw from "./components/UploadedFilesRaw"

type Props = {
  dataset: DataSet
}

const DatasetDetailsLeftColumnRaw = (p: Props) => {
  const { hasWriteAccess } = useResourceAccess(p.dataset.uuid)

  return (
    <Grid container spacing={5} direction={"column"}>
      <Grid item>
        <UploadedFilesRaw dataset={p.dataset} />
      </Grid>
      {hasWriteAccess && (
        <Grid item>
          <FileToUploadRaw dataset={p.dataset} />
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(DatasetDetailsLeftColumnRaw)
