import { getAccessToken, msalInstance } from "../../auth/msalConfig"
import type { APIResultsDto } from "../../sdk/utils/entities/sdk.dataset.types"
import { ResourceKind } from "../../sdk/utils/entities/sdk.resource.types"
import type { getTokenFnType, requestFnType } from "../../sdk/utils/requests/sdk.request.types"
import { requestFn } from "../../sdk/utils/requests/sdk.requests"
import type { ApiResponse } from "../response.types"
import { createDeleteUserDto, createUserDto } from "./resourceAccess.helpers"
import type { AccessRoleId, ResourceAccess, UserIdentifier } from "./resourceAccess.types"

class ResourceAccessManager {
  requestFn: requestFnType
  userId?: string

  constructor(getTokenFn: getTokenFnType) {
    this.requestFn = async (endpoint, requestOptions?) => await requestFn(endpoint, getTokenFn, requestOptions)
  }

  async getUserId(): Promise<string> {
    if (this.userId) return this.userId

    const userId = msalInstance.getActiveAccount()?.localAccountId

    if (userId) {
      this.userId = userId
      return userId
    }

    if (!userId) {
      if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
      }
      const fetchedId = msalInstance.getActiveAccount()?.localAccountId
      if (fetchedId) {
        this.userId = fetchedId
        return fetchedId
      }
    }

    throw new Error("User id not found")
  }

  async getAccessLevelForResource(resourceId: string): Promise<ApiResponse<ResourceAccess[]>> {
    return await this.requestFn(
      `api/odcat-services/v1/resources/relationships/?object=${resourceId}&subject=${await this.getUserId()}`
    )
  }

  async getDatasetUsers(resourceId: string): Promise<ApiResponse<ResourceAccess[]>> {
    return await this.requestFn(`api/odcat-services/v1/resources/relationships/?object=${resourceId}`)
  }

  async addDatasetUser(
    params: {
      resourceId: string
      roleId: AccessRoleId
    } & UserIdentifier
  ): Promise<ApiResponse<ResourceAccess>> {
    return await this.requestFn("api/odcat-services/v1/resources/relationships/", {
      method: "POST",
      body: createUserDto(params),
    })
  }

  async removeDatasetUser({
    resourceId,
    userAzureId,
    roleId,
  }: {
    resourceId: string
    userAzureId: string
    roleId: AccessRoleId
  }): Promise<ApiResponse<ResourceAccess>> {
    return await this.requestFn("api/odcat-services/v1/resources/relationships/", {
      method: "DELETE",
      body: createDeleteUserDto({ userAzureId, roleId, resourceId }),
    })
  }

  async getSharedResources<T>(kind: ResourceKind, parrentUuid?: string): Promise<ApiResponse<APIResultsDto<T>>> {
    return await this.requestFn(
      `api/odcat-services/v1/resources/?subject=${await this.getUserId()}&includeOwner=false&kind=${encodeURIComponent(
        kind
      )}`
    )
  }

  async getSharedDatasetsInCollection<T>(parrentUuid?: string): Promise<ApiResponse<APIResultsDto<T>>> {
    return await this.requestFn(
      `api/odcat-services/v1/resources/?kind=${encodeURIComponent(ResourceKind.dataset)}${
        parrentUuid ? `&parentUuid=${parrentUuid}` : ""
      }`
    )
  }

  async getSharedCollections<DataCollectionDtoGet>() {
    return await this.getSharedResources<DataCollectionDtoGet>(ResourceKind.collection)
  }

  async getSharedDatasets<DatasetDtoGet>(parrentUuid?: string): Promise<ApiResponse<APIResultsDto<DatasetDtoGet>>> {
    return await this.getSharedResources<DatasetDtoGet>(ResourceKind.dataset, parrentUuid)
  }
}

export const accessManager = new ResourceAccessManager(getAccessToken)
