import { jParse } from "../../../api/jsonInfinity.mjs"
import type { ApiResponse } from "../../../api/response.types"
import type { FetchArgs, RequestOptions, RequestParams } from "./sdk.request.types"

const API_URL = process.env.REACT_APP_API_URL
export const STREAM_DOWNLOAD_SIZE_LIMIT = 200 // in MB

export enum SemanticError {
  incompleteResponse = "Data Limitation Alert: Incomplete Data Received.",
}

export const createFetchApiBody = (body?: any, isFile?: boolean) => {
  if (!body) return undefined
  if (!isFile) return JSON.stringify(body)
  return body
}

const generateHeaders = (token: string, requestOptions?: RequestOptions): HeadersInit => {
  const headers: Record<string, string> = { Authorization: `Bearer ${token}` }
  if (!requestOptions?.omitContentType) headers["Content-Type"] = "application/json"
  if (requestOptions?.chunkedHeader) headers["X-ODP-CHUNKED-ENCODING"] = "0"

  return headers
}

export const generateFetchArgs = (token: string, endpoint: string, requestOptions?: RequestOptions): FetchArgs => {
  const headers = generateHeaders(token, requestOptions)
  const url = new URL(endpoint, API_URL)
  if (requestOptions?.params) {
    Object.keys(requestOptions.params).forEach(key => {
      if (!requestOptions.params) return // never happens but typescript doesn't know that
      const value = requestOptions.params[key as keyof RequestParams]
      if (value === undefined) return
      url.searchParams.append(key, value.toString())
    })
  }

  return [
    url,
    {
      method: requestOptions?.method,
      headers,
      body: createFetchApiBody(requestOptions?.body, requestOptions?.omitContentType),
    },
  ]
}

export class NDJSONChunkParser {
  private buffer: string

  constructor() {
    this.buffer = ""
  }

  parseChunk(chunk: string): object[] {
    this.buffer += chunk
    const objects: object[] = []
    let braceStack: number = 0
    let startIndex: number = 0

    for (let i = 0; i < this.buffer.length; i++) {
      if (this.buffer[i] === "{") {
        braceStack++
        if (braceStack === 1) {
          startIndex = i
        }
      } else if (this.buffer[i] === "}") {
        braceStack--
        if (braceStack === 0) {
          try {
            const obj: object = jParse(this.buffer.substring(startIndex, i + 1))
            objects.push(obj)
          } catch (e) {
            console.log(e)
          }
        }
      }
    }

    // Clear the buffer if all braces are closed, else keep the incomplete part
    if (braceStack === 0) {
      this.buffer = ""
    } else {
      this.buffer = this.buffer.substring(startIndex)
    }

    return objects
  }

  getBuffer(): string {
    return this.buffer
  }
}

export async function streamNDJsonPage({
  fetchParams,
  parser,
  nRows,
  maxBytes,
  cursor,
}: {
  fetchParams: FetchArgs
  parser: NDJSONChunkParser
  nRows?: number
  maxBytes: number
  cursor?: string
}): Promise<{
  response: ApiResponse<any>
  isFinished: boolean
  nextCursor?: string
  dataByteSize?: number
  isIncomplete?: boolean
}> {
  const allObjects: object[] = []

  let totalSizeBytes = 0
  let nextCursor: string | undefined
  const rowsLimit = nRows ?? Infinity

  let statusCode: number = 499 // this will be overwritten but typescript is not able to infer that this is always set

  const decoder = new TextDecoder()

  try {
    const fetchUrl = fetchParams[0]
    if (cursor) fetchUrl.searchParams.append("cursor", cursor)
    const response = await fetch(...fetchParams)
    statusCode = response.status

    if (!response.ok) {
      const jResp = await response.json()
      const errorText = jResp.error ? jResp.error : response.statusText
      return {
        response: { error: { text: errorText, statusCode: response.status }, data: undefined },
        isFinished: true,
      }
    }

    if (!response.body) throw new Error("No response body")

    const reader = response.body.getReader()

    while (allObjects.length < rowsLimit && totalSizeBytes < maxBytes) {
      const { done, value } = await reader.read()
      if (done) break

      const chunk = decoder.decode(value, { stream: true })
      totalSizeBytes += chunk.length

      const parsedChunk = parser.parseChunk(chunk)
      allObjects.push(...parsedChunk)

      if (allObjects.length >= rowsLimit || totalSizeBytes >= maxBytes) break
    }

    await reader.cancel() // Close the stream
  } catch (error) {
    return {
      response: { error: { text: "Requesting data failed.", statusCode }, data: undefined },
      isFinished: true,
    }
  }

  // Handle data recieved but unable to combine into objects
  if (allObjects.length === 0 && parser.getBuffer().length > 0) {
    return {
      response: {
        error: { text: SemanticError.incompleteResponse, statusCode },
        data: [],
      },
      isFinished: true,
      isIncomplete: true,
    }
  }

  // Handle API error response
  if (allObjects.length === 1 && "error" in allObjects[0]) {
    return {
      response: { error: { text: allObjects[0].error as string, statusCode }, data: undefined },
      isFinished: true,
    }
  }

  if (allObjects.length >= rowsLimit) {
    return { response: { data: allObjects.slice(0, rowsLimit), error: undefined }, isFinished: true }
  }

  const lastRow = allObjects[allObjects.length - 1]

  if ("@@end" in lastRow) {
    allObjects.pop()
    return { response: { data: allObjects, error: undefined }, isFinished: true }
  }

  if ("@@next" in lastRow) {
    nextCursor = lastRow["@@next"] as string
    allObjects.pop()
  }

  return {
    response: { data: allObjects.slice(0, rowsLimit), error: undefined },
    isFinished: false,
    dataByteSize: totalSizeBytes,
    nextCursor,
  }
}

export async function streamNDJsonData(
  fetchParams: FetchArgs,
  parser: NDJSONChunkParser,
  nRows = Infinity,
  maxMB = STREAM_DOWNLOAD_SIZE_LIMIT
): Promise<ApiResponse<any>> {
  const bytesToDownloadLimit = maxMB * 1024 * 1024 // Convert megabytes to bytes

  let maxBytesLeft = bytesToDownloadLimit
  let rowsLeft = nRows

  let allObjects: object[] = []
  let currentCursor: string | undefined

  while (maxBytesLeft > 0 && rowsLeft > 0) {
    const { response, isFinished, dataByteSize, nextCursor, isIncomplete } = await streamNDJsonPage({
      fetchParams,
      parser,
      nRows: rowsLeft,
      maxBytes: maxBytesLeft,
      cursor: currentCursor,
    })

    currentCursor = nextCursor
    if (dataByteSize) maxBytesLeft -= dataByteSize
    if (response.data) rowsLeft -= response.data.length

    if (response.error) {
      if (!isIncomplete) return response
      if (isIncomplete && allObjects.length === 0) return response
    }

    allObjects = allObjects.concat(response.data as object[])

    if (isFinished) break
  }

  return { data: allObjects }
}

export const generateCaseInsensitivePattern = (inputString: string): string => {
  // Use Array.from to ensure proper handling of Unicode characters (if needed)
  return Array.from(inputString)
    .map(char => {
      if (char.match(/[a-zA-Z]/)) {
        // If the character is alphabetic, include both lowercase and uppercase in the pattern
        return `[${char.toLowerCase()}${char.toUpperCase()}]`
      } else {
        // If the character is not alphabetic, escape it if it's a special regex character
        // TypeScript doesn't have a built-in escape function, so this checks for special regex characters
        const specialChars = "\\^$*+?.()|[]{}"
        if (specialChars.includes(char)) {
          return `\\${char}`
        } else {
          return char
        }
      }
    })
    .join("")
}
