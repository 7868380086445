import { type ApiResponse } from "../../../api/response.types"
import { NDJSONChunkParser, generateFetchArgs, streamNDJsonData } from "./sdk.request.helpers"
import type { FetchArgs, RequestOptions, getTokenFnType } from "./sdk.request.types"

/**
 * Request function for endpoints that return standard json response
 * @param fetchArgs arguments to pass to fetch function call
 * @returns api resonse
 */
const standardJsonRequest = async <T>(fetchArgs: FetchArgs): Promise<ApiResponse<T>> => {
  const result = await fetch(...fetchArgs)

  if (result.ok) return { data: await result.json() }

  const resultBody = await result.json()

  if (resultBody.errors) {
    return {
      errors: {
        list: resultBody.errors,
        statusCode: result.status,
      },
    }
  }

  return {
    error: { text: resultBody.error ? resultBody.error : result.statusText, statusCode: result.status },
  }
}

/**
 * Request function for endpoints that return blob object i.e. file
 * @param fetchArgs arguments to pass to fetch function call
 * @returns api resonse
 */
const downloadFileRequest = async (fetchArgs: FetchArgs) => {
  const result = await fetch(...fetchArgs)

  if (result.ok) {
    return { data: result as any }
  }
  return { error: { text: result.statusText, statusCode: result.status }, data: undefined }
}

/**
 * Request function for endpoints that return ndjson stream
 * @param fetchArgs arguments to pass to fetch function call
 * @param limitedStreamOptions.maxMB maximum size of response in MB
 * @param limitedStreamOptions.maxObjects maximum number of objects in response
 * @returns api resonse
 */
export const streamRequest = async (
  fetchArgs: FetchArgs,
  limitedStreamOptions?: RequestOptions["limitedStreamOptions"]
): Promise<ApiResponse<any>> => {
  const parser = new NDJSONChunkParser()

  return await streamNDJsonData(fetchArgs, parser, limitedStreamOptions?.maxObjects, limitedStreamOptions?.maxMb)
}

export const requestFn = async <T>(
  endpoint: string,
  getToken: getTokenFnType,
  requestOptions?: RequestOptions
): Promise<ApiResponse<T>> => {
  const token = await getToken()
  if (!token) throw new Error("Missing access token")

  const fetchArgs: FetchArgs = generateFetchArgs(token, endpoint, requestOptions)

  if (requestOptions?.useLimitedStream) {
    return await streamRequest(fetchArgs, requestOptions?.limitedStreamOptions)
  }

  if (requestOptions?.isFileDownloadRequest) {
    return await downloadFileRequest(fetchArgs)
  }

  return await standardJsonRequest(fetchArgs)
}
