import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import React, { useCallback, useEffect } from "react"
import { createResponse } from "../utils/redux/queries/queries.helpers"
import { jsSDK } from "../utils/sdk/jsSDK"
import { STREAM_DOWNLOAD_SIZE_LIMIT } from "../utils/sdk/utils/requests/sdk.request.helpers"
import CustomAlert from "./CustomAlert"
import { StyledButton } from "./StyledButton"
import { StyledLoadingButton } from "./StyledLoadingButton"
import { downloadAsCsv } from "./exportCsv.helpers"

type Props = {
  open: boolean
  closeFn: () => void
  datasetId: string
}

const ExportCsvDialog = (p: Props) => {
  const maxObjectsInputRef = React.useRef<HTMLInputElement>(null)
  const maxMbInputRef = React.useRef<HTMLInputElement>(null)

  const [showLoader, setShowLoader] = React.useState(false)
  const [error, setError] = React.useState<FetchBaseQueryError | undefined>()

  const clickExport = useCallback(async () => {
    setError(undefined)

    const maxMb = maxMbInputRef.current?.value ? parseFloat(maxMbInputRef.current?.value) : undefined
    const maxObjects = maxObjectsInputRef.current?.value ? parseInt(maxObjectsInputRef.current?.value) : undefined

    setShowLoader(true)
    const res = await createResponse(
      async () =>
        await jsSDK.datasets.getDatapointsByDatasetNameId({
          datasetNameId: p.datasetId,
          limitedStreamOptions: {
            maxMb,
            maxObjects,
          },
        }),
      el => el
    )

    if ("data" in res) {
      downloadAsCsv(res.data)
    } else if ("error" in res) {
      setError(res.error)
    }
    setShowLoader(false)
  }, [p.datasetId])

  useEffect(() => {
    return () => {
      setShowLoader(false)
      setError(undefined)
    }
  }, [])

  return (
    <Dialog
      open={p.open}
      onClose={p.closeFn}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { p: 3, overflowY: "visible" } }}>
      <DialogTitle id="export-to-csv-dialog" fontWeight={700}>
        {"Export to CSV"}
      </DialogTitle>
      <DialogContent sx={{ justifyContent: "center", overflowY: "hidden" }}>
        {error && <CustomAlert error={error} sx={{ mb: 2 }} />}
        <TextField
          inputRef={maxObjectsInputRef}
          size="small"
          type="number"
          label="Number of rows"
          fullWidth
          sx={{ mt: 1, mb: 2 }}
          helperText={"Leave empty for no limit."}
        />
        <TextField
          inputRef={maxMbInputRef}
          size="small"
          type="number"
          label="Data size limit (MB)"
          fullWidth
          defaultValue={"5"}
          helperText={`Enter the download limit in megabytes (max ${STREAM_DOWNLOAD_SIZE_LIMIT}MB). After conversion, the CSV file will be smaller.`}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <StyledLoadingButton onClick={clickExport} variant={"contained"} loading={showLoader}>
          Export
        </StyledLoadingButton>
        <StyledButton onClick={p.closeFn} variant="outlined">
          Close
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ExportCsvDialog)
