import React from "react"
import { useDatasets } from "../../../../utils/hooks/resourceHooks"
import { useAppSelector } from "../../../../utils/redux/store"
import { ResourceTypeOdp } from "../../../../utils/sdk/utils/entities/sdk.resource.types"
import MyResources from "../MyResources/MyResources"

type Props = any

const MyDatasets = (p: Props) => {
  const { showInternallyShared } = useAppSelector(state => state.datasetsReducer.filters)

  const { datasets, sharedDatasets, isFetching, errors } = useDatasets({
    isPublic: false,
    isInternallyShared: showInternallyShared,
  })

  return (
    <MyResources
      resources={datasets}
      isFetching={isFetching}
      errors={errors}
      resourceType={ResourceTypeOdp.Dataset}
      sharedResources={sharedDatasets}
    />
  )
}

export default React.memo(MyDatasets)
