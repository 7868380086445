import React from "react"
import { useCollections } from "../../../utils/hooks/resourceHooks"
import { useAppSelector } from "../../../utils/redux/store"
import { ResourceTypeOdp } from "../../../utils/sdk/utils/entities/sdk.resource.types"
import MyResources from "./MyResources/MyResources"

type Props = any

const MyCollections = (p: Props) => {
  const { showInternallyShared } = useAppSelector(state => state.datasetsReducer.filters)

  const { collections, isFetching, errors, sharedCollections } = useCollections({
    isPublic: false,
    isInternallyShared: showInternallyShared,
  })

  return (
    <MyResources
      resources={collections}
      isFetching={isFetching}
      errors={errors}
      resourceType={ResourceTypeOdp.Collection}
      sharedResources={sharedCollections}
    />
  )
}

export default React.memo(MyCollections)
