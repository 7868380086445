import React, { type PropsWithChildren } from "react"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material"
import { colorPalette } from "../../../../../styling/theme"
import type { DataEntity } from "../../../../../utils/frontendTypes/dataEntity.types"
import type { ResourceTypeOdp } from "../../../../../utils/sdk/utils/entities/sdk.resource.types"
import ResourceTile from "../../ResourceTile/ResourceTile"

type Props = {
  title?: string
  dataEntities?: DataEntity[]
  resourceType: ResourceTypeOdp
}
const MyDataSection = (p: PropsWithChildren<Props>) => {
  return (
    <Accordion
      defaultExpanded={true}
      elevation={0}
      sx={{
        background: "transparent",
        "&::before": { display: "none" },
        "&.Mui-expanded": { my: 0 },
      }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          borderBottom: `1px solid ${colorPalette.deepPurpleO10}`,

          "&.Mui-expanded": {
            minHeight: 56,
            borderBottom: "none",
          },
          "& .MuiAccordionSummary-content": {
            my: "12px",
            "&.Mui-expanded": {
              my: "12px", // Keeps margin consistent when expanded
            },
          },
        }}>
        <Typography variant="h6">{p.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          {p.dataEntities?.map(dataColl => (
            <Grid item sx={{ pr: 1, pb: 1, flexGrow: 1 }} key={dataColl.uuid} xs={12} sm={6} md={4} lg={3}>
              <ResourceTile {...dataColl} resourceType={p.resourceType} testId="dataCollectionTile" />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default React.memo(MyDataSection)
