import EditIcon from "@mui/icons-material/Edit"
import { Box, Grid } from "@mui/material"
import { skipToken } from "@reduxjs/toolkit/query"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import DetailsHeader from "../../components/DetailsHeader/DetailsHeader"
import EditResourceModal from "../../components/EditResourceModal/EditResourceModal"
import MyCollectionDatsets from "../../components/MyCollectionDatasets/MyCollectionDatsets"
import PageLoader from "../../components/PageLoader.tsx/PageLoader"
import { StyledSectionHeader } from "../../components/StyledTypography"
import { paths } from "../../components/navigation.helpers"
import { colorPalette } from "../../styling/theme"
import { useResourceAccess } from "../../utils/auth/userHooks"
import { useGetCollectionMetadataByNameIdQuery } from "../../utils/redux/queries/catalog.queries"
import { useDeleteResourceMutation } from "../../utils/redux/queries/dataset.queries"
import { ResourceTypeOdp } from "../../utils/sdk/utils/entities/sdk.resource.types"
import { useErrorRedirects } from "../Catalog/catalog.helpers"
import CatalogPreviewButton from "../MyDataset/components/MyDatasetMetadata/components/CatalogPreviewButton"
import ResourceObservables from "../MyDataset/components/MyDatasetTools/components/DatasetObservables/ResourceObservables"
import ManageAccess from "../MyDataset/components/MyDatasetTools/components/ManageAccess/ManageAccess"
import CollectionMetadata from "./components/CollectionMetadata/CollectionMetadata"
import MyCollectionAddDataset from "./components/MyCollectionAddDataset"

const MyCollection = () => {
  const navigate = useNavigate()

  const [editModalOpen, setEditModalOpen] = useState(false)

  const closeModalFn = useCallback(() => {
    setEditModalOpen(false)
  }, [])

  const [, { isLoading: deleteInProgress, isSuccess: deleted }] = useDeleteResourceMutation()

  const { collectionID } = useParams()

  const skipGetting = !collectionID || deleteInProgress || deleted
  const {
    data: dataCollection,
    isLoading,
    isFetching,
    error,
  } = useGetCollectionMetadataByNameIdQuery(skipGetting ? skipToken : collectionID)
  useErrorRedirects(error)

  const { hasWriteAccess } = useResourceAccess(dataCollection?.uuid)

  useEffect(() => {
    if (!collectionID || deleted) {
      navigate(paths["My Data"])
    }

    if (error && "status" in error && error.status === 404) {
      navigate(paths.NotFound)
    }
  }, [navigate, collectionID, deleted, error])

  if (!dataCollection || isLoading || isFetching) return <PageLoader color={colorPalette.deepPurpleO50} />

  const clickEdit = () => {
    setEditModalOpen(true)
  }

  const collectionDatasetsHeader = <StyledSectionHeader>Datasets:</StyledSectionHeader>

  return (
    <Box sx={{ pl: 4, pt: 2 }}>
      <DetailsHeader
        clickEdit={hasWriteAccess ? clickEdit : undefined}
        icon={<EditIcon />}
        text={dataCollection.name}
        type="Data Collection"
        description={dataCollection.description || undefined}
        tags={dataCollection.tags}
      />
      <Grid container sx={{ marginTop: "50px", minHeight: "500px", pb: 2 }}>
        {/* Left column */}
        <Grid item xs={12} sm={6} sx={{ borderRight: { sm: "1px solid black" }, pr: 4, py: 2 }}>
          <Grid container spacing={2} direction={"column"}>
            <Grid item>
              <CollectionMetadata dataCollection={dataCollection} />
            </Grid>
          </Grid>
        </Grid>

        {/* Right column */}
        <Grid item xs={12} sm={6} sx={{ px: { sm: 4 }, py: 2 }}>
          <Grid container spacing={4} direction={"column"}>
            <Grid item>
              <ManageAccess dataset={dataCollection} resourceType={ResourceTypeOdp.Collection} />
            </Grid>
            <Grid item>
              <StyledSectionHeader sx={{ mb: 1 }}>Collection preview</StyledSectionHeader>
              <CatalogPreviewButton nameId={dataCollection.nameId} resourceType={ResourceTypeOdp.Collection} />
            </Grid>
            <Grid item>
              <MyCollectionDatsets
                isMyData={true}
                collection={dataCollection}
                headerComponent={collectionDatasetsHeader}
              />
              {hasWriteAccess && <MyCollectionAddDataset collectionId={dataCollection.nameId} />}
            </Grid>
            <Grid item>
              <ResourceObservables
                nameId={dataCollection.nameId}
                type={ResourceTypeOdp.Collection}
                resourceUUID={dataCollection.uuid}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <EditResourceModal
        resourceUuid={dataCollection.uuid}
        open={editModalOpen}
        closeFn={closeModalFn}
        type="DataCollection"
        mainInfo={{
          name: dataCollection.name,
          description: dataCollection.description,
          tags: dataCollection.tags ?? [],
        }}
      />
    </Box>
  )
}

export default MyCollection
