import { usePostHog } from "posthog-js/react"
import React, { useCallback } from "react"
import AutocompleteSearch from "../../../../components/AutocompleteSearch/AutocompleteSearch"
import { PosthogEvent } from "../../../../utils/analytics/customEvents"
import { setCatalogSearchString } from "../../../../utils/redux/reducers/catalogReducer"
import { useAppDispatch } from "../../../../utils/redux/store"

const AutocompleteSearchCatalog = () => {
  const dispatch = useAppDispatch()

  const posthog = usePostHog()

  const onSubmit = useCallback(
    (searchString: string) => {
      dispatch(setCatalogSearchString(searchString))
      posthog.capture(PosthogEvent.CatalogSearch, { search_string: searchString })
    },
    [dispatch, posthog]
  )

  return <AutocompleteSearch onSubmit={onSubmit} />
}

export default React.memo(AutocompleteSearchCatalog)
