import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import { Box, Link, Typography } from "@mui/material"
import { usePostHog } from "posthog-js/react"
import { useEffect } from "react"
import { colorPalette } from "../../../../styling/theme"
import { useUserId } from "../../../../utils/auth/userHooks"
import { externalPaths } from "../../../navigation.helpers"
import SignInButton from "../../../SignInButton/SignInButton"
import NavUserLoggedIn from "./components/NavUserLoggedIn"

const NavUser = () => {
  const posthog = usePostHog()

  const userId = useUserId()

  useEffect(() => {
    posthog.register({ user_id: userId ?? "anonymous" })
  }, [posthog, userId])

  return (
    <Box sx={{ flexGrow: 0 }}>
      <UnauthenticatedTemplate>
        <Box display={"flex"}>
          <Box sx={{ mr: 3 }}>
            <Typography variant="subtitle2">{"Not a member?"}</Typography>
            <Typography variant="subtitle2">
              <Link
                href={externalPaths.RequestAccessForm}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: colorPalette.luminousGreen, textDecoration: "underline" }}>
                Apply for access
              </Link>
            </Typography>
          </Box>
          <SignInButton />
        </Box>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <NavUserLoggedIn />
      </AuthenticatedTemplate>
    </Box>
  )
}

export default NavUser
