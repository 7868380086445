import CustomAlert from "../../../../components/CustomAlert"
import PageLoader from "../../../../components/PageLoader.tsx/PageLoader"
import { useCollections } from "../../../../utils/hooks/resourceHooks"
import { ResourceTypeOdp } from "../../../../utils/sdk/utils/entities/sdk.resource.types"
import CatalogResult from "./CatalogResult"
import { S } from "./CatalogResultsList.style"
import NoResults from "./components/NoResults"

const CatalogCollectionsList = () => {
  const { collections, isFetching, noPossibleResults, errors } = useCollections({ isPublic: true })

  const error = errors.length ? errors[0] : undefined // this only sends one request so there will only be one error

  if (isFetching) return <PageLoader />

  if (error) return <CustomAlert error={error} />
  if (!collections?.length || noPossibleResults) return <NoResults />

  return (
    <S.ResultsWrapper>
      <S.Results>
        {collections.map(el => (
          <CatalogResult dataSource={el} key={"catalogResult" + el.uuid} resultType={ResourceTypeOdp.Collection} />
        ))}
      </S.Results>
    </S.ResultsWrapper>
  )
}

export default CatalogCollectionsList
