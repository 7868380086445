import { Box, Typography } from "@mui/material"
import React, { useState } from "react"
import CustomAlert from "../../../../../../components/CustomAlert"
import { Loader } from "../../../../../../components/Loader/Loader"
import { StyledButton } from "../../../../../../components/StyledButton"
import { StyledSectionHeader } from "../../../../../../components/StyledTypography"
import { colorPalette } from "../../../../../../styling/theme"
import { AccessRoleId } from "../../../../../../utils/api/AccessManagement/resourceAccess.types"
import { useLoggedInUserInfo, useResourceAccess } from "../../../../../../utils/auth/userHooks"
import type { DataEntity } from "../../../../../../utils/frontendTypes/dataEntity.types"
import { useAddResourceUserMutation } from "../../../../../../utils/redux/queries/access.queries"
import type { ResourceTypeOdp } from "../../../../../../utils/sdk/utils/entities/sdk.resource.types"
import ManageAccessModal from "./components/ManageAccessModal/ManageAccessModal"

type Props = {
  dataset: DataEntity
  resourceType: ResourceTypeOdp
}

const ManageAccess = (p: Props) => {
  const { isAdmin, isEditor, isViewer, isFetching, error, isNoDefinedRole } = useResourceAccess(p.dataset.uuid)
  const [addUser, { isLoading: isLoadingMakeAdmin, error: makeAdminError }] = useAddResourceUserMutation()
  const user = useLoggedInUserInfo()

  const [manageAccessModalOpen, setManageAccessModalOpen] = useState(false)
  const closeDialog = () => setManageAccessModalOpen(false)

  const isOwner = p.dataset.ownerId === user.azureId
  const showMakeAdminBtn = isOwner && isNoDefinedRole
  const showNoRole = isNoDefinedRole && !showMakeAdminBtn

  const clickMakeAdmin = async () => {
    if (!user.email) return // should never happen
    await addUser({ resourceId: p.dataset.uuid, userEmail: user.email, roleId: AccessRoleId.Admin })
  }

  const loading = isFetching || isLoadingMakeAdmin

  return (
    <>
      <StyledSectionHeader sx={{ mb: 1 }}>{`${p.resourceType} access`}</StyledSectionHeader>
      {loading && (
        <Box sx={{ ml: 10, mt: 2 }}>
          <Loader size={25} color={colorPalette.deepPurpleO30} />
        </Box>
      )}
      {error && <CustomAlert error={error} sx={{ mb: 1 }} />}
      {makeAdminError && <CustomAlert error={makeAdminError} sx={{ mb: 1 }} />}

      {!loading && (
        <>
          {isAdmin && (
            <Typography>
              {`You are an admin of this ${p.resourceType.toLocaleLowerCase()}. You can view, edit, manage permissions, and
          configure advanced settings to control how this dataset is accessed and used.`}
            </Typography>
          )}
          {isEditor && (
            <Typography>
              You are an editor of this dataset. You can view and modify the data, allowing you to update and make
              changes as needed.
            </Typography>
          )}
          {isViewer && (
            <Typography>
              You are a viewer of this dataset. You can view and explore the data, but you do not have permission to
              make any changes.
            </Typography>
          )}
          {isAdmin && (
            <StyledButton
              variant="contained"
              onClick={() => setManageAccessModalOpen(true)}
              sx={{ display: "block", mt: 2 }}>
              Manage Access
            </StyledButton>
          )}
          {showNoRole && ( // fallback - this should never happen
            <Typography>
              You do not have a defined role for this dataset. You can request access from the owner or an admin.
            </Typography>
          )}
          {showMakeAdminBtn && (
            <>
              <Typography>
                {`You are an owner of this ${p.resourceType.toLowerCase()}. You can make yourself an admin to manage permissions and configure advanced settings.`}
              </Typography>
              <StyledButton variant="contained" onClick={clickMakeAdmin} sx={{ display: "block", mt: 2 }}>
                Make me an admin
              </StyledButton>
            </>
          )}
        </>
      )}

      <ManageAccessModal open={manageAccessModalOpen} closeFn={closeDialog} dataEntity={p.dataset} />
    </>
  )
}

export default React.memo(ManageAccess)
