import ClearIcon from "@mui/icons-material/Clear"
import { Avatar, Box, IconButton } from "@mui/material"
import React, { useEffect } from "react"
import { Loader } from "../../../../../../../../../../../components/Loader/Loader"
import { colorPalette } from "../../../../../../../../../../../styling/theme"
import type {
  AccessRoleId,
  ResourceUser,
} from "../../../../../../../../../../../utils/api/AccessManagement/resourceAccess.types"
import { useRemoveResourceUserMutation } from "../../../../../../../../../../../utils/redux/queries/access.queries"
import { setAccessLevelErrorRA } from "../../../../../../../../../../../utils/redux/reducers/datasetsReducer"
import { useAppDispatch } from "../../../../../../../../../../../utils/redux/store"

const ContributorEmail = (p: ResourceUser & { showDelete?: boolean; resourceUuid: string; roleId: AccessRoleId }) => {
  const [removeUser, { isLoading, isSuccess, error }] = useRemoveResourceUserMutation()
  const dispatch = useAppDispatch()
  const removeContributor = async () => {
    const confirmed = window.confirm(`Are you sure you want to remove ${p.email} from the contributors?`)
    if (confirmed) await removeUser({ resourceId: p.resourceUuid, userAzureId: p.id, roleId: p.roleId })
  }

  useEffect(() => {
    if (isSuccess) {
      alert("User has been removed from the contributors")
    }
  }, [isSuccess])

  useEffect(() => {
    dispatch(setAccessLevelErrorRA(error))
  }, [error, dispatch])

  return (
    <>
      {isLoading && (
        <Box display={"flex"} justifyContent={"center"} sx={{ py: 1 }}>
          <Loader size={25} />
        </Box>
      )}
      {!isLoading && (
        <Box display={"flex"} alignItems={"center"}>
          <Avatar>{p.email[0].toUpperCase()}</Avatar>
          <Box marginLeft={1}>{p.email}</Box>
          {p.showDelete && (
            <IconButton sx={{ ml: "auto" }} onClick={removeContributor} disabled={isLoading}>
              <ClearIcon sx={{ color: colorPalette.deepPurpleO20 }} />
            </IconButton>
          )}
        </Box>
      )}
    </>
  )
}

export default React.memo(ContributorEmail)
