import { Box, Grid, Tooltip, Typography } from "@mui/material"
import React from "react"

import PeopleIcon from "@mui/icons-material/People"
import { colorPalette } from "../../styling/theme"
import ResourceHeader from "../ResourceHeader/ResourceHeader"
import OptionalEditButton from "./components/OptionalEditButton"

type Props = {
  icon: React.ReactNode
  clickEdit?: () => void
  text: string
  type: "Dataset" | "Data Collection"
  description?: string
  tags?: string[] | null
  isInternallyShared?: boolean
}

const DetailsHeader = (p: Props) => {
  return (
    <Grid container justifyContent="space-between">
      <Grid item container flexDirection={"column"} textAlign="start" width={"80%"}>
        <ResourceHeader
          name={p.text}
          type={p.type}
          optionalButton={p.clickEdit && <OptionalEditButton clickEdit={p.clickEdit} icon={p.icon} />}
        />
        <Box sx={{ mt: 3 }}>
          <Typography color={colorPalette.deepPurpleO60} component="span">
            Description:{" "}
          </Typography>
          <Typography component="span">{p.description}</Typography>
        </Box>
        {!!p.tags?.length && (
          <Box>
            <Typography color={colorPalette.deepPurpleO60} component="span">
              {"Keywords: "}
            </Typography>
            <Typography component="span">{p.tags.join(", ")}</Typography>
          </Box>
        )}
      </Grid>
      <Grid item>
        {p.isInternallyShared && (
          <Tooltip title={"Internally shared"} placement="left">
            <PeopleIcon sx={{ fontSize: 48, color: colorPalette.deepPurple, mr: 3 }} />
          </Tooltip>
        )}
      </Grid>
    </Grid>
  )
}

export default React.memo(DetailsHeader)
