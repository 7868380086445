import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useMemo } from "react"
import { type AuthorizedGroups } from "../../pages/Projects/project.helpers"
import { AccessRoleId, type ResourceUser } from "../api/AccessManagement/resourceAccess.types"
import type { LoggedInUser } from "../frontendTypes/datasets.types"
import { useGetAccessLevelForResourceUUIDQuery, useGetDatasetUsersQuery } from "../redux/queries/access.queries"
import { loginRequest } from "./msalConfig"

const INTERNALLY_SHARD_GROUP_ID = "08b2e5aa-f9f9-444a-b84f-6b452f71c40c"

export const useUserId = (): string | undefined => {
  const { instance } = useMsal()
  return instance.getActiveAccount()?.idTokenClaims?.oid
}

export const useLoggedInUserInfo = (): LoggedInUser => {
  const { instance } = useMsal()
  const claims = instance.getActiveAccount()?.idTokenClaims

  if (!claims?.email) throw new Error("Missing token claims")

  const groups = (claims?.groups as string[]) || []

  const user: LoggedInUser = {
    name: claims.name as string,
    email: claims.email as string,
    family_name: claims.family_name as string | undefined,
    given_name: claims.given_name as string | undefined,
    groups,
    azureId: claims.oid as string,
    isInternal: groups?.includes(INTERNALLY_SHARD_GROUP_ID),
  }

  return user
}

export const useLoginFn = () => {
  const { instance, inProgress } = useMsal()

  const handleLogin = () => {
    if (inProgress !== InteractionStatus.None) return
    instance.loginRedirect(loginRequest).catch(e => {
      console.log(e)
    })
  }

  return { handleLogin }
}

export const useUserAuthorizationGroups = (): AuthorizedGroups[] => {
  const { instance } = useMsal()
  const claims = instance.getActiveAccount()?.idTokenClaims

  return (claims?.groups as AuthorizedGroups[]) || []
}

export const useIsUserOwner = (ownerId?: string): boolean => {
  const userId = useLoggedInUserInfo().azureId

  return userId === ownerId
}

export const useResourceAccess = (resourceUUID?: string) => {
  const {
    data: resourceRelationship,
    isFetching,
    error,
  } = useGetAccessLevelForResourceUUIDQuery(resourceUUID ?? skipToken)

  const isAdmin = resourceRelationship?.role === AccessRoleId.Admin
  const isEditor = resourceRelationship?.role === AccessRoleId.Editor
  const isViewer = resourceRelationship?.role === AccessRoleId.Viewer

  const isNoDefinedRole = !isAdmin && !isEditor && !isViewer

  return { isAdmin, isEditor, isViewer, isFetching, error, isNoDefinedRole, hasWriteAccess: isAdmin || isEditor }
}

export type ResourceUsers = { admins: ResourceUser[]; editors: ResourceUser[]; viewers: ResourceUser[] }

export const useResourceUsers = ({ skip, resourceUUID }: { skip: boolean; resourceUUID: string }) => {
  const { data: resourceRelationships, isFetching, error } = useGetDatasetUsersQuery(skip ? skipToken : resourceUUID)

  const { admins, editors, viewers } = useMemo(() => {
    if (!resourceRelationships) {
      return { admins: [], editors: [], viewers: [] } // return empty arrays if resourceUsers is null or undefined
    }

    return resourceRelationships.reduce(
      (acc: ResourceUsers, relationship) => {
        if (relationship.role === AccessRoleId.Admin) acc.admins.push(relationship.subject)
        if (relationship.role === AccessRoleId.Editor) acc.editors.push(relationship.subject)
        if (relationship.role === AccessRoleId.Viewer) acc.viewers.push(relationship.subject)

        return acc
      },
      { admins: [], editors: [], viewers: [] } // initial value for the accumulator
    )
  }, [resourceRelationships])

  return { resourceUsers: { admins, editors, viewers }, isFetching, error }
}
