import { Box, FormControl, Grid, Modal, TextField, Typography } from "@mui/material"
import { MuiChipsInput } from "mui-chips-input"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import CustomAlert from "../../../components/CustomAlert"
import { StyledButton } from "../../../components/StyledButton"
import { StyledLoadingButton } from "../../../components/StyledLoadingButton"
import type { createDataCollectionFrontend } from "../../../utils/frontendTypes/dataCollection.types"
import { createMyCollectionPath } from "../../../utils/frontendTypes/datasets.types"
import { useAddDataCollectionMutation } from "../../../utils/redux/queries/dataset.queries"
import { setAddDataCollectionPopupVisibleRA } from "../../../utils/redux/reducers/datasetsReducer"
import { useAppDispatch, useAppSelector } from "../../../utils/redux/store"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "800px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 7,
}

const AddDataCollectionModal = () => {
  const form = useForm<createDataCollectionFrontend>({
    defaultValues: {
      name: "",
      description: "",
      tags: [] as string[],
    },
  })

  const open = useAppSelector(state => state.datasetsReducer.showAddDataCollectionPopup)
  const dispatch = useAppDispatch()
  const [addCollection, { isLoading, error }] = useAddDataCollectionMutation()
  const navigate = useNavigate()
  const tagInputRef = React.useRef<HTMLInputElement>(null)
  const [tagText, setTagText] = React.useState("")

  const addDatasetClick = async () => {
    if (tagText) {
      form.setValue("tags", [...(form.getValues().tags ?? []), tagText])
      setTagText("")
    }

    const response = await addCollection(form.getValues())

    if (!response.data) return
    dispatch(setAddDataCollectionPopupVisibleRA(false))
    navigate(createMyCollectionPath(response.data.nameId))
  }

  const close = () => {
    dispatch(setAddDataCollectionPopupVisibleRA(false))
  }

  return (
    <Modal open={open} onClose={close} aria-labelledby="add-collection-modal-title" data-testid="addCollectionModal">
      <Grid container sx={style} direction="column">
        <form onSubmit={form.handleSubmit(addDatasetClick)}>
          <Grid item>
            <Typography id="add-collection-modal-title" variant="h6" component="h2" sx={{ marginBottom: "30px" }}>
              Add New Data Collection
            </Typography>
            {error && <CustomAlert error={error} sx={{ mb: 2, mt: -2 }} />}
          </Grid>
          <Grid item>
            <FormControl sx={{ display: "flex" }}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Controller
                    name="name"
                    control={form.control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={"Name"}
                        fullWidth
                        error={!!form.formState.errors.name}
                        helperText={form.formState.errors.name?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="description"
                    control={form.control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Description"
                        multiline
                        rows={4}
                        error={!!form.formState.errors.description}
                        helperText={form.formState.errors.description?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="tags"
                    control={form.control}
                    render={({ field }) => (
                      <MuiChipsInput
                        {...field}
                        label="Keywords"
                        placeholder={!form.getValues().tags?.length ? "Keywords (e.g. temperature, salinity)" : ""}
                        fullWidth
                        addOnWhichKey={["Enter", ","]}
                        helperText={"Add keywords (separate with commas)."}
                        ref={tagInputRef}
                        inputValue={tagText}
                        onInputChange={value => setTagText(value)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

          <Box sx={{ marginTop: "50px", alignSelf: "flex-start" }}>
            <StyledLoadingButton variant="contained" type="submit" loading={isLoading}>
              Done
            </StyledLoadingButton>
            <StyledButton variant="text" onClick={close} sx={{ ml: 1 }}>
              Cancel
            </StyledButton>
          </Box>
        </form>
      </Grid>
    </Modal>
  )
}

export default React.memo(AddDataCollectionModal)
