import {
  BrowserAuthError,
  EventType,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
  type SilentRequest,
} from "@azure/msal-browser"
import type { AccountInfo } from "@azure/msal-common"
import { checkIfloggingOn } from "../utils"

const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_susi_v2",
  },
  authorities: {
    signUpSignIn: {
      authority: "https://oceandataplatform.b2clogin.com/oceandataplatform.onmicrosoft.com/B2C_1A_signup_signin_custom",
    },
  },
  authorityDomain: "oceandataplatform.b2clogin.com",
}

const loggingOn = checkIfloggingOn()

const loggerOptions = loggingOn
  ? {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return undefined
          default:
            return undefined
        }
      },
    }
  : {}

const msalConfig = {
  auth: {
    clientId: "473167a6-0748-4cfa-8853-571c6b8a80fe",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: `${window.location.protocol}//${window.location.host}/`,
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions,
  },
}

export const loginRequest = {
  scopes: ["openid", "profile", "email"],
}

export const msalInstance = new PublicClientApplication(msalConfig)

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback(event => {
  const payload = event.payload as any

  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    payload?.account
  ) {
    msalInstance.setActiveAccount(payload.account as AccountInfo)
  }
})

export const getAccessToken = async () => {
  const request: SilentRequest = {
    scopes: [process.env.REACT_APP_API_SCOPE as string],
  }

  try {
    const tokenResponse = await msalInstance.acquireTokenSilent(request)
    return tokenResponse.accessToken
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      return await msalInstance.acquireTokenRedirect(request)
    }

    if (e instanceof BrowserAuthError && e.errorCode === "monitor_window_timeout") {
      return await msalInstance.acquireTokenRedirect(request)
    }

    console.error(e)
  }
}
