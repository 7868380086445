import "mapbox-gl/dist/mapbox-gl.css"
import React from "react"
import { useBBoxMiniMap, type MapVersion } from "../../../utils/hooks/useMiniMap"

type Props = {
  boundingBox?: GeoJSON.Polygon
  width?: string
  height?: string
  containerId: string
  mapVersion?: MapVersion
}

const MiniMap = ({ boundingBox, width = "100%", height = "350px", containerId = "minimap", mapVersion }: Props) => {
  useBBoxMiniMap({ containerId, bbox: boundingBox, mapVersion })

  return <div id={containerId} style={{ width, height, borderRadius: "4px" }} />
}

export default React.memo(MiniMap)
