import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { Box, Tooltip } from "@mui/material"
import React from "react"
import { StyledSectionHeader } from "../../../../../../../../../components/StyledTypography"
import { colorPalette } from "../../../../../../../../../styling/theme"

type Props = {
  groupName: string
  explanationText: string
}

const AccesGroupHeader = (p: Props) => {
  return (
    <Box sx={{ mb: 2 }} display="flex" alignItems={"center"}>
      <StyledSectionHeader>{p.groupName}</StyledSectionHeader>
      <Tooltip title={p.explanationText} placement="right" arrow>
        <Box sx={{ pl: 1.5 }} display="flex" alignItems={"center"}>
          <HelpOutlineIcon fontSize={"small"} sx={{ color: colorPalette.deepPurpleO30 }} />
        </Box>
      </Tooltip>
    </Box>
  )
}

export default React.memo(AccesGroupHeader)
