import React from "react"
import { type DataSet } from "../../../utils/frontendTypes/datasets.types"
import { createContact } from "../../../utils/sdk/helpers"
import DataDetailsTable from "../../CatalogCollection/components/DataDetailsTable/DataDetailsTable"

const DataAccessTable = (p: DataSet) => {
  const data = {
    "Managed by": p.fillableMetadata?.maintanierOrganisation,
    "Manager contact":
      p.fillableMetadata?.contactEmail && p.fillableMetadata?.contactLastName
        ? createContact({
            firstName: p.fillableMetadata?.contactFirstName,
            lastName: p.fillableMetadata?.contactLastName,
            email: p.fillableMetadata?.contactEmail,
          })
        : null,
  }

  return <DataDetailsTable data={data} />
}

export default React.memo(DataAccessTable)
