import { type TimeRangeMiliSeconds } from "../frontendTypes/datasets.types"
import { getResourceKindForResourceType } from "../frontendTypes/datasetType.helpers"
import {
  OBSERVABLE_VERSION,
  ObservableCls,
  type ObservableDtoCreate,
  type ObservableDtoGet,
  type TimeRangeObservableValue,
} from "../sdk/utils/entities/sdk.observable.types"
import { ResourceKind, type ResourceTypeOdp } from "../sdk/utils/entities/sdk.resource.types"

export const getBBoxFromObservables = (observables: ObservableDtoGet<any>[]) => {
  const found = observables.find(el => !!el.spec.details.value.coordinates)

  return found?.spec.details.value.geometry.coordinates[0]
}

const createReferenceId = (nameId: string, type: ResourceTypeOdp) => `${getResourceKindForResourceType(type)}/${nameId}`

export const createBBoxObservableDto = ({
  nameId,
  type,
  geometry,
}: {
  nameId: string
  type: ResourceTypeOdp
  geometry: GeoJSON.Polygon
}): ObservableDtoCreate<GeoJSON.Geometry> => {
  return {
    kind: ResourceKind.observable,
    version: OBSERVABLE_VERSION,
    metadata: {
      name: `${nameId}-bbox-observable`,
    },
    spec: {
      ref: createReferenceId(nameId, type),
      observable_class: ObservableCls.BBox,
      details: {
        value: geometry,
        attribute: "coverage",
      },
    },
  }
}

export const createTimeRangeObservableDto = ({
  nameId,
  type,
  timeRange,
}: {
  nameId: string
  type: ResourceTypeOdp
  timeRange: TimeRangeMiliSeconds
}): ObservableDtoCreate<TimeRangeObservableValue> => {
  return {
    kind: ResourceKind.observable,
    version: OBSERVABLE_VERSION,
    metadata: {
      name: `${nameId}-timerange-observable`,
    },
    spec: {
      ref: createReferenceId(nameId, type),
      observable_class: ObservableCls.TimeRange,
      details: {
        value: timeRange,
        attribute: "coverage",
      },
    },
  }
}
