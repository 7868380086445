import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

export const applicationSegments = {
  Dashboard: "/",
  Catalog: "/catalog",
  "My Data": "/data_collections",
}

export type PageNames = keyof typeof applicationSegments

export const externalSegments = {
  Documentation: "http://docs.hubocean.earth/",
  Community: "https://ocean-data-community.slack.com/",
}

export type ExternalPageNames = keyof typeof externalSegments

export const externalPaths = {
  ...externalSegments,
  Connector: "https://workspace.hubocean.earth",
  TabularUploadNotebook:
    "https://github.com/C4IROcean/OceanDataConnector/blob/master/workflow-examples/tabular-data-upload-ILIAD-example/example_publish_iliad.ipynb",
  RequestAccessForm: "https://issues.hubocean.earth/form/709ec86c-4b3b-479f-9231-f9e8e5c91167",
}

export const paths = {
  ...applicationSegments,
  Datasets: "/datasets",
  CatalogCollection: "/catalog/collection/:id",
  CatalogDataset: "/catalog/dataset/:id",
  NotFound: "/404",
  Unauthorized: "/401",
  Projects: "/apps",
}

export const useOdpNavigate = () => {
  const nav = useNavigate()
  const odpInternalNav = useCallback(
    (pageName: PageNames) => {
      const path = applicationSegments[pageName] || "/"
      nav(path)
    },
    [nav]
  )

  const odpExternalNav = useCallback((pageName: ExternalPageNames) => {
    const path = externalSegments[pageName]
    window.open(path)
  }, [])

  return { odpInternalNav, odpExternalNav }
}
