import React, { useState } from "react"
import { StyledButton } from "../../../components/StyledButton"
import AddDatasetModal from "../../MyData/components/AddDatasetModal"

type Props = {
  collectionId: string
}

const MyCollectionAddDataset = (p: Props) => {
  const [addDatasetModalVisible, setAddDatasetModalVisible] = useState(false)

  return (
    <>
      <StyledButton variant="contained" onClick={() => setAddDatasetModalVisible(true)} sx={{ mt: 2 }}>
        Add Dataset
      </StyledButton>
      <AddDatasetModal
        open={addDatasetModalVisible}
        setOpen={setAddDatasetModalVisible}
        dataCollectionId={p.collectionId}
      />
    </>
  )
}

export default React.memo(MyCollectionAddDataset)
