import { Box, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material"
import { MuiChipsInput } from "mui-chips-input"
import React, { useMemo } from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import CustomAlert from "../../../components/CustomAlert"
import { StyledButton } from "../../../components/StyledButton"
import { StyledLoadingButton } from "../../../components/StyledLoadingButton"
import { type ApiResponse } from "../../../utils/api/response.types"
import { useLoggedInUserInfo } from "../../../utils/auth/userHooks"
import {
  DatasetType,
  createMyDatasetPath,
  type CreateDataSet,
  type DataSet,
} from "../../../utils/frontendTypes/datasets.types"
import { mapLoggedInUserInfoToContact } from "../../../utils/frontendTypes/frontendMappers"
import { useAddDatasetMutation } from "../../../utils/redux/queries/dataset.queries"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "800px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 7,
}

type Props = {
  open: boolean
  setOpen: React.Dispatch<boolean>
  dataCollectionId: string
}

const AddDatasetModal = (p: Props) => {
  const form = useForm({
    defaultValues: {
      name: "",
      description: "",
      type: DatasetType.Files as DatasetType,
      tags: [] as string[],
    },
  })
  const [tagText, setTagText] = React.useState("")

  const user = useLoggedInUserInfo()

  const personContact = useMemo(() => mapLoggedInUserInfoToContact(user), [user])

  const [addDataset, { isLoading, error }] = useAddDatasetMutation()
  const navigate = useNavigate()

  const close = () => {
    p.setOpen(false)
  }

  const addDatasetClick = async () => {
    if (tagText) {
      form.setValue("tags", [...(form.getValues().tags ?? []), tagText])
      setTagText("")
    }
    const dataset: CreateDataSet = {
      ...form.getValues(),
      tags: form.getValues().tags.map(el => el.toLowerCase()),
      collectionId: p.dataCollectionId,
      fillableMetadata: {
        ...personContact,
      },
    }

    const response = (await addDataset(dataset)) as ApiResponse<DataSet>

    if (!response.data) return
    const typedResponse = response as { data: DataSet }
    close()
    navigate(createMyDatasetPath(typedResponse.data.nameId))
  }

  return (
    <Modal open={p.open} onClose={close} aria-labelledby="add-dataset-modal-title" data-testid="addDatasetModal">
      <Grid container sx={style} direction="column">
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={form.handleSubmit(addDatasetClick)}>
          <Grid item>
            <Typography id="add-dataset-modal-title" variant="h6" component="h2" sx={{ marginBottom: "30px" }}>
              Add New Dataset
            </Typography>
            {error && <CustomAlert error={error} sx={{ mb: 2, mt: -2 }} />}
          </Grid>
          <Grid item>
            <FormControl sx={{ display: "flex" }}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Controller
                    name="name"
                    control={form.control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={"Name"}
                        fullWidth
                        error={!!form.formState.errors.name}
                        helperText={form.formState.errors.name?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel id="type-label">type</InputLabel>
                    <Controller
                      name="type"
                      control={form.control}
                      render={({ field }) => (
                        <Select labelId="level-label" label="type" fullWidth {...field}>
                          {Object.keys(DatasetType).map((el, i) => (
                            <MenuItem key={"select__" + el} value={Object.values(DatasetType)[i]}>
                              {el}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <Controller
                    name="description"
                    control={form.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Description"
                        multiline
                        rows={4}
                        error={!!form.formState.errors.description}
                        helperText={form.formState.errors.description?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="tags"
                    control={form.control}
                    render={({ field }) => (
                      <MuiChipsInput
                        {...field}
                        label="Keywords"
                        placeholder={!form.getValues().tags.length ? "Keywords (e.g. temperature, salinity)" : ""}
                        fullWidth
                        addOnWhichKey={["Enter", ","]}
                        helperText={"Add keywords (separate with commas)."}
                        inputValue={tagText}
                        onInputChange={value => setTagText(value)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

          <Box sx={{ marginTop: "50px", alignSelf: "flex-start" }}>
            <StyledLoadingButton variant="contained" type="submit" loading={isLoading}>
              Done
            </StyledLoadingButton>
            <StyledButton variant="text" onClick={close} sx={{ ml: 1 }}>
              Cancel
            </StyledButton>
          </Box>
        </form>
      </Grid>
    </Modal>
  )
}

export default React.memo(AddDatasetModal)
