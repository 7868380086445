import { useMsal } from "@azure/msal-react"
import { Avatar, Box, IconButton, Menu, MenuItem, Typography } from "@mui/material"
import React from "react"
import { colorPalette } from "../../../../../styling/theme"
import { useLoggedInUserInfo } from "../../../../../utils/auth/userHooks"

const NavUserLoggedIn = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  const { instance } = useMsal()
  const user = useLoggedInUserInfo()

  const handleLogout = async () => {
    await instance.logoutRedirect()
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <Box sx={{ flexGrow: 0 }} position={"relative"}>
      <Box alignItems={"center"} display={"flex"} onClick={handleOpenUserMenu} sx={{ cursor: "pointer" }}>
        <Box display={{ lg: "block", xs: "none" }} marginRight={2} color={colorPalette.deepPurpleO10}>
          <Typography variant="subtitle1" lineHeight={1.1}>
            {user.name}
          </Typography>
          <Typography variant="subtitle1" lineHeight={1.1}>
            {user.email}
          </Typography>
        </Box>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} data-testid="userAvatar">
          <Avatar alt={user.name} src="/static/images/avatar/2.jpg" />
        </IconButton>
      </Box>
      {Boolean(anchorElUser) && ( // this overrides standard 'open' prop logic because anchorElUser.offsetWidth as width value causes flickering when unmounting is delayed
        <Menu
          sx={{ mt: "45px" }}
          PaperProps={{ sx: { width: { lg: anchorElUser?.offsetWidth } } }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          onClose={handleCloseUserMenu}>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <MenuItem key={"logout"} onClick={handleLogout}>
            <Typography textAlign="center">Logout</Typography>
          </MenuItem>
        </Menu>
      )}
    </Box>
  )
}

export default NavUserLoggedIn
