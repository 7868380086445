import React, { useCallback, useEffect, useMemo, useState } from "react"
import MinimapWithClick from "../../../../../components/MinimapWithClick.tsx/MinimapWithClick"
import RefineLocationMapModal from "../../../../../components/RefineLocationMapModal/RefineLocationMapModal"
import { setMyDataBounidingBoxFilterRA } from "../../../../../utils/redux/reducers/datasetsReducer"
import { useAppDispatch, useAppSelector } from "../../../../../utils/redux/store"

type Props = {
  openRefineLocation: () => void
}

const MyDataMap = (p: Props) => {
  const boundingBox = useAppSelector(state => state.datasetsReducer.filters.boundingBox)
  const dispatch = useAppDispatch()

  const [refineModalOpen, setRefineModalOpen] = useState(false)
  const clickOpenRefineModal = useCallback(() => setRefineModalOpen(true), [])
  const clickCloseRefineModal = useCallback(() => setRefineModalOpen(false), [])
  const clickClearLocationFilter = useCallback(() => dispatch(setMyDataBounidingBoxFilterRA()), [dispatch])
  const actionButtonProps = useMemo(
    () => ({
      onClick: (geometry: GeoJSON.Polygon) => dispatch(setMyDataBounidingBoxFilterRA(geometry)),
      text: "Done",
    }),
    [dispatch]
  )

  useEffect(() => {
    return () => {
      dispatch(setMyDataBounidingBoxFilterRA())
    }
  }, [dispatch])

  return (
    <>
      <MinimapWithClick
        clickButton={clickOpenRefineModal}
        sxProps={{ width: { sm: "100%" }, height: "210px", position: "relative" }}
        containerId="filter-map-my-data"
        boundingBox={boundingBox}
        closeButtonClick={clickClearLocationFilter}
      />
      <RefineLocationMapModal
        open={refineModalOpen}
        closeFn={clickCloseRefineModal}
        actionButtonProps={actionButtonProps}
      />
    </>
  )
}

export default React.memo(MyDataMap)
