import type { ResourceKind } from "../../sdk/utils/entities/sdk.resource.types"

export enum AccessRoleId {
  Viewer = "3",
  Editor = "2",
  Admin = "1",
}

// For v1 of access controll only user will be supported
export enum UserType {
  user = "user",
  serviceAccount = "serviceAccount",
  userGroup = "userGroup",
}

export type ResourceAccess = {
  object: RelationshipObject
  role: AccessRoleId
  subject: ResourceUser
}

export type CreateResourceAccessDto = {
  object: RelationshipObject
  role: AccessRoleId
  subject: RelationshipSubject
}

export type RemoveResourceAccessDto = {
  object: {
    id: string
    kind: ResourceKind
  }
  role: AccessRoleId
  subject: RelationshipSubjectId
}

export type ResourceUser = {
  email: string
  id: string
  type: UserType
}

type RelationshipSubjectId = {
  id: string
}

type RelationshipSubject = { email?: string; id?: string }

type RelationshipObject = {
  id: string
  kind: ResourceKind
}

export type UserIdentifier = { userEmail: string; userId?: string } | { userId: string; userEmail?: string }
