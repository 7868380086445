import type { PostHog } from "posthog-js"
import { checkIfProduction } from "../utils"

export const posthogInit = (posthog: PostHog) => {
  const isProduction = checkIfProduction()

  if (isProduction) {
    posthog.init("phc_mLTPnFTSJa39ojWKe08q1xrQEbm2eQKESEiTTnDmNuB", {
      api_host: "https://eu.i.posthog.com",
      disable_external_dependency_loading: true, // Optional - will ensure we never try to load extensions lazily
    })
  }
}
