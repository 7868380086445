import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"

import { Box, IconButton, InputAdornment, TextField } from "@mui/material"
import { ClearIcon } from "@mui/x-date-pickers"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import LabeledIconButton from "../../../../../../../../../../../components/CollectionDatasets/LabeledIconButton"
import { Loader } from "../../../../../../../../../../../components/Loader/Loader"
import { colorPalette } from "../../../../../../../../../../../styling/theme"
import type { AccessRoleId } from "../../../../../../../../../../../utils/api/AccessManagement/resourceAccess.types"
import { useAddResourceUserMutation } from "../../../../../../../../../../../utils/redux/queries/access.queries"
import { setAccessLevelErrorRA } from "../../../../../../../../../../../utils/redux/reducers/datasetsReducer"
import { useAppDispatch } from "../../../../../../../../../../../utils/redux/store"
import { EMAIL_REGEX } from "../../../../../../../../../../../utils/sdk/helpers"

type Props = {
  roleId: AccessRoleId
  addButtonText: string
  resourceUuid: string
}

const AddingUser = (p: Props) => {
  const [addUser, { isLoading, error }] = useAddResourceUserMutation()
  const dispatch = useAppDispatch()

  const [showAddReaderInput, setShowAddReaderInput] = useState(false)
  const form = useForm({ defaultValues: { userEmail: "" } })

  const addUserSubmit = async () => {
    await addUser({
      resourceId: p.resourceUuid,
      userEmail: form.getValues("userEmail"),
      roleId: p.roleId,
    })
  }

  const clickClear = () => {
    setShowAddReaderInput(false)
  }

  useEffect(() => {
    dispatch(setAccessLevelErrorRA(error))
  }, [error, dispatch])

  useEffect(() => {
    if (!showAddReaderInput) form.reset()
  }, [showAddReaderInput, form])

  return (
    <>
      {isLoading && (
        <Box width={"100%"} display="flex" justifyContent={"center"} sx={{ mt: 2 }}>
          <Loader size={25} />
        </Box>
      )}
      {!isLoading && (
        <>
          {!showAddReaderInput && (
            <LabeledIconButton
              icon={<AddCircleOutlineIcon />}
              label={p.addButtonText}
              onClick={() => setShowAddReaderInput(true)}
              sx={{ mt: 2 }}
            />
          )}
          {showAddReaderInput && (
            <form onSubmit={form.handleSubmit(addUserSubmit)}>
              <Controller
                name={"userEmail"}
                control={form.control}
                rules={{ pattern: { value: EMAIL_REGEX, message: "Invalid email address" } }}
                render={({ field, formState }) => {
                  return (
                    <TextField
                      {...field}
                      label="Email address"
                      value={field.value || ""}
                      error={!!formState.errors.userEmail}
                      helperText={formState.errors.userEmail?.message?.toString()}
                      placeholder={"example@mail.com"}
                      size={"small"}
                      sx={{ mt: 2 }}
                      fullWidth
                      InputProps={{
                        sx: { pr: 0.5 },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton type="submit" size="small">
                              <AddCircleOutlineIcon sx={{ color: colorPalette.deepPurpleO20 }} />
                            </IconButton>
                            <IconButton onClick={clickClear} size="small">
                              <ClearIcon sx={{ color: colorPalette.deepPurpleO20 }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )
                }}
              />
            </form>
          )}
        </>
      )}
    </>
  )
}

export default React.memo(AddingUser)
