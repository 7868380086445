import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { QueryStatus } from "@reduxjs/toolkit/query"
import React, { useCallback, useEffect, useState } from "react"
import { StyledButton } from "../../../../../../../components/StyledButton"
import { type BoundingMapboxCoords } from "../../../../../../../utils/frontendTypes/datasets.types"
import { useAddBBoxObservableMutation } from "../../../../../../../utils/redux/queries/dataset.queries"
import type { ResourceTypeOdp } from "../../../../../../../utils/sdk/utils/entities/sdk.resource.types"
import GeolocManualInputs from "../../../../../../Catalog/components/CatalogRefineLocation/components/GeolocManualInputs"
import MapWithDraw from "../../../../../../Catalog/components/CatalogRefineLocation/components/MapWithDraw"

type Props = {
  open: boolean
  setOpen: React.Dispatch<boolean>
  nameId: string
  type: ResourceTypeOdp
}

const AddObservableModal = ({ setOpen, open, nameId, type }: Props) => {
  const [drawnGeometry, setDrawnGeometry] = useState<GeoJSON.Polygon>()
  const [numericCoords, setNumericCoords] = useState<BoundingMapboxCoords>()

  const [addObservable, result] = useAddBBoxObservableMutation()

  const close = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const clickAdd = () => {
    if (!drawnGeometry) {
      alert("Draw polygon to add observable.")
      return
    }
    void addObservable({ geometry: drawnGeometry, nameId, type })
  }

  useEffect(() => {
    if (result.status === QueryStatus.fulfilled) close()
  }, [result, close])

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="xl" PaperProps={{ sx: { p: 3 } }}>
      <DialogTitle id="edit-bbox-title" fontWeight={700}>
        {"Add Bounding Box"}
      </DialogTitle>
      <DialogContent>
        {open && <GeolocManualInputs startingGeometry={drawnGeometry} clickOverrideCoords={setNumericCoords} />}
        <MapWithDraw setDrawnGeometry={setDrawnGeometry} numericCoords={numericCoords} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <StyledButton variant="contained" onClick={clickAdd}>
          Add
        </StyledButton>
        <StyledButton onClick={close}>Cancel</StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(AddObservableModal)
