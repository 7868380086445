import EditIcon from "@mui/icons-material/Edit"
import { Box, IconButton } from "@mui/material"
import React from "react"
import { StyledSectionHeader } from "../../../../../components/StyledTypography"

type Props = {
  headerText: string
  clickEditFn?: () => void
  testId?: string
}

const HeaderWithEdit = (p: Props) => {
  return (
    <Box display={"flex"} alignItems={"center"}>
      <StyledSectionHeader>{p.headerText}</StyledSectionHeader>
      {p.clickEditFn && (
        <IconButton onClick={p.clickEditFn} sx={{ ml: 0.5 }} data-testid={p.testId}>
          <EditIcon sx={{ fontSize: 16 }} />
        </IconButton>
      )}
    </Box>
  )
}

export default React.memo(HeaderWithEdit)
