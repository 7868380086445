import { Box } from "@mui/material"
import React, { useEffect } from "react"
import { type BoundingMapboxCoords } from "../../../../../utils/frontendTypes/datasets.types"
import { useMapWithDraw } from "../useMapWithDraw"

type Props = {
  setDrawnGeometry: React.Dispatch<GeoJSON.Polygon | undefined>
  numericCoords?: BoundingMapboxCoords
}

const MapWithDraw = ({ setDrawnGeometry, numericCoords }: Props) => {
  const { drawnGeometry, draw } = useMapWithDraw(undefined, "refine-map")

  useEffect(() => {
    setDrawnGeometry(drawnGeometry)
  }, [drawnGeometry, setDrawnGeometry])

  useEffect(() => {
    if (!draw || !numericCoords) return

    const currentFeature = draw?.getAll().features[0]

    const geometry = {
      type: "Polygon" as const,
      coordinates: [numericCoords],
    }

    const feature = {
      id: currentFeature.id,
      type: "Feature" as const,
      properties: {},
      geometry,
    }
    draw?.add(feature)
    setDrawnGeometry(geometry)
    draw.changeMode("simple_select")
  }, [setDrawnGeometry, numericCoords, draw])

  return (
    <>
      <Box sx={{ width: "100%", height: "850px", position: "relative" }}>
        <div id="refine-map" style={{ width: "100%", height: "100%" }} />
      </Box>
    </>
  )
}

export default React.memo(MapWithDraw)
