import { Grid, Stack, Typography } from "@mui/material"
import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import _ from "lodash"
import React from "react"
import CustomAlert from "../../../../components/CustomAlert"
import PageLoader from "../../../../components/PageLoader.tsx/PageLoader"
import type { DataEntity } from "../../../../utils/frontendTypes/dataEntity.types"
import type { ResourceTypeOdp } from "../../../../utils/sdk/utils/entities/sdk.resource.types"
import MyDataSection from "./components/MyDataSection"

type Props = {
  resources?: DataEntity[]
  sharedResources?: DataEntity[]
  isFetching?: boolean
  errors?: (FetchBaseQueryError | SerializedError)[]
  resourceType: ResourceTypeOdp
}

const MyResources = (p: Props) => {
  if (p.isFetching) return <PageLoader />
  if (p.errors?.length) {
    return p.errors.map((error, index) => <CustomAlert key={"fetch_error_" + index} error={error} sx={{ mt: 1 }} />)
  }

  const [internallySharedResources, privateResources] = _.partition(
    p.resources,
    resource => !!resource.isInternalyShared
  )

  const noResources = !p.resources?.length && !p.sharedResources?.length

  return (
    <Stack sx={{ mt: 3 }}>
      {!!privateResources.length && (
        <MyDataSection title="Private" dataEntities={privateResources} resourceType={p.resourceType} />
      )}
      {!!internallySharedResources.length && (
        <MyDataSection
          title="Internally shared"
          dataEntities={internallySharedResources}
          resourceType={p.resourceType}
        />
      )}
      {!!p.sharedResources?.length && (
        <MyDataSection title="Shared" dataEntities={p.sharedResources} resourceType={p.resourceType} />
      )}

      {noResources && (
        <Grid item data-testid={"noMyDataSearchResults"}>
          <Typography>{`You don’t have any ${p.resourceType.toLowerCase()}s here yet. Add one to get started!`}</Typography>
        </Grid>
      )}
    </Stack>
  )
}

export default React.memo(MyResources)
