/* eslint-disable @typescript-eslint/no-invalid-void-type */
import type { AccessRoleId, ResourceAccess } from "../../api/AccessManagement/resourceAccess.types"
import { accessManager } from "../../api/AccessManagement/resourceAccessManager"
import type { DataCollection } from "../../frontendTypes/dataCollection.types"
import type { DataSet } from "../../frontendTypes/datasets.types"
import { mapToDataCollection, mapToDataset } from "../../frontendTypes/frontendMappers"
import type { DataCollectionDtoGet } from "../../sdk/utils/entities/sdk.dataCollection.types"
import type { APIResultsDto, DatasetDtoGet } from "../../sdk/utils/entities/sdk.dataset.types"
import { datasetsApi } from "./dataset.queries"
import { createResponse } from "./queries.helpers"

export enum AccessTags {
  DatasetAccess = "AllCatalogEntries",
  DatasetUsers = "DatasetUsers",
  DatasetsInCollection = "DatasetsInCollection",
}

const apiEnhanced = datasetsApi.enhanceEndpoints({ addTagTypes: Object.values(AccessTags) })

export const resourceAccessApi = apiEnhanced.injectEndpoints({
  endpoints: builder => ({
    getAccessLevelForResourceUUID: builder.query<ResourceAccess | null, string>({
      queryFn: async resurceId => {
        return await createResponse<ResourceAccess[], ResourceAccess | null>(
          async () => await accessManager.getAccessLevelForResource(resurceId),
          el => (el?.length ? el[0] : null) // TODO: fix after API update
        )
      },
      providesTags: (_, __, datasetNameId) => [{ type: AccessTags.DatasetAccess, id: datasetNameId }],
    }),

    getDatasetUsers: builder.query<ResourceAccess[], string>({
      queryFn: async resurceId => {
        return await createResponse<ResourceAccess[], ResourceAccess[]>(
          async () => await accessManager.getDatasetUsers(resurceId),
          el => el // On API side this should always return an array with one element
        )
      },
      providesTags: (_, __, resourceId) => {
        const res = [{ type: AccessTags.DatasetUsers, id: resourceId }]
        return res
      },
    }),

    getSharedDatasets: builder.query<DataSet[], void>({
      queryFn: async () => {
        return await createResponse<APIResultsDto<DatasetDtoGet>, DataSet[]>(
          async () => await accessManager.getSharedDatasets(),
          data => data.results.map(el => mapToDataset(el, true))
        )
      },
      providesTags: [AccessTags.DatasetAccess],
    }),

    getSharedDatasetsInCollection: builder.query<DataSet[], string | undefined>({
      queryFn: async parrentUuid => {
        return await createResponse<APIResultsDto<DatasetDtoGet>, DataSet[]>(
          async () => await accessManager.getSharedDatasetsInCollection(parrentUuid),
          data => data.results.map(el => mapToDataset(el, true))
        )
      },
      providesTags: [AccessTags.DatasetsInCollection],
    }),

    getSharedCollections: builder.query<DataCollection[], void>({
      queryFn: async () => {
        return await createResponse<APIResultsDto<DataCollectionDtoGet>, DataCollection[]>(
          async () => await accessManager.getSharedCollections(),
          data => data.results.map(el => mapToDataCollection(el, true))
        )
      },
      providesTags: [AccessTags.DatasetAccess],
    }),

    addResourceUser: builder.mutation<ResourceAccess, { resourceId: string; userEmail: string; roleId: AccessRoleId }>({
      queryFn: async ({ resourceId, userEmail, roleId }) => {
        return await createResponse<ResourceAccess, ResourceAccess>(
          async () => await accessManager.addDatasetUser({ resourceId, userEmail, roleId }),
          el => el
        )
      },
      invalidatesTags: (_, error, arg) => {
        if (error) return []
        const res = [{ type: AccessTags.DatasetUsers, id: arg.resourceId }, { type: AccessTags.DatasetAccess }]
        return res
      },
    }),

    removeResourceUser: builder.mutation<
      ResourceAccess,
      { resourceId: string; userAzureId: string; roleId: AccessRoleId }
    >({
      queryFn: async ({ resourceId, userAzureId, roleId }) => {
        return await createResponse<ResourceAccess, ResourceAccess>(
          async () => await accessManager.removeDatasetUser({ resourceId, userAzureId, roleId }),
          el => el
        )
      },
      invalidatesTags: (_, error, arg) => {
        if (error) return []
        return [{ type: AccessTags.DatasetUsers, id: arg.resourceId }, { type: AccessTags.DatasetAccess }]
      },
    }),
  }),
})

export const {
  useGetAccessLevelForResourceUUIDQuery,
  useGetDatasetUsersQuery,
  useAddResourceUserMutation,
  useRemoveResourceUserMutation,
  useGetSharedDatasetsQuery,
  useGetSharedDatasetsInCollectionQuery,
  useGetSharedCollectionsQuery,
} = resourceAccessApi
