import { Box, Divider } from "@mui/material"
import React from "react"
import { colorPalette } from "../../../../styling/theme"
import { useOdpNavigate, type ExternalPageNames, type PageNames } from "../../../navigation.helpers"
import type { NavProps } from "../HamburgerMenu/HamburgerMenu"
import DesktopNavItem from "./components/DesktopNavItem"

const DesktopNav = (p: NavProps) => {
  const { odpExternalNav, odpInternalNav } = useOdpNavigate()

  const nav = (page: PageNames) => {
    odpInternalNav(page)
  }

  const externalNav = (page: ExternalPageNames) => {
    odpExternalNav(page)
  }

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" }, alignSelf: "stretch" }}>
      {p.pages.map(page => (
        <DesktopNavItem key={page} pageName={page} onClick={() => nav(page)} />
      ))}
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ color: "red", mr: 2, background: colorPalette.deepPurpleO50, my: 2.5 }}
      />
      {p.externalPages.map(externalPage => (
        <DesktopNavItem
          key={externalPage}
          isExternal={true}
          pageName={externalPage}
          onClick={() => externalNav(externalPage)}
        />
      ))}
    </Box>
  )
}

export default React.memo(DesktopNav)
