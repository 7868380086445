import { Grid } from "@mui/material"
import React from "react"
import { StyledSectionHeader } from "../../../../components/StyledTypography"
import { type DataSet } from "../../../../utils/frontendTypes/datasets.types"
import { ResourceTypeOdp } from "../../../../utils/sdk/utils/entities/sdk.resource.types"
import CatalogPreviewButton from "../MyDatasetMetadata/components/CatalogPreviewButton"
import ResourceObservables from "./components/DatasetObservables/ResourceObservables"
import ManageAccess from "./components/ManageAccess/ManageAccess"
import MyDatasetParentCollection from "./components/MyDatasetParentCollection/MyDatasetParentCollection"

type Props = {
  dataset: DataSet
}

const MyDatasetTools = (p: Props) => {
  return (
    <Grid container spacing={5} direction="column">
      <Grid item>
        <ManageAccess dataset={p.dataset} resourceType={ResourceTypeOdp.Dataset} />
      </Grid>

      <Grid item>
        <StyledSectionHeader sx={{ mb: 1 }}>Dataset preview</StyledSectionHeader>
        <CatalogPreviewButton nameId={p.dataset.nameId} resourceType={ResourceTypeOdp.Dataset} />
      </Grid>

      <Grid item>
        <MyDatasetParentCollection dataset={p.dataset} />
      </Grid>

      <Grid item>
        <ResourceObservables nameId={p.dataset.nameId} type={ResourceTypeOdp.Dataset} resourceUUID={p.dataset.uuid} />
      </Grid>
    </Grid>
  )
}

export default React.memo(MyDatasetTools)
