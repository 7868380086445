import ClearIcon from "@mui/icons-material/Clear"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { Box, IconButton, type SxProps } from "@mui/material"
import "mapbox-gl/dist/mapbox-gl.css"
import React from "react"
import { useBBoxMiniMap } from "../../utils/hooks/useMiniMap"

type Props = {
  clickButton: () => void
  closeButtonClick: () => void
  sxProps: SxProps
  containerId: string
  boundingBox?: GeoJSON.Polygon
}

const MinimapWithClick = (p: Props) => {
  useBBoxMiniMap({ containerId: p.containerId, bbox: p.boundingBox, mapVersion: "globe" })

  const buttonSx = {
    position: "absolute",
    m: 1,
    right: 0,
    zIndex: 1,
    backgroundColor: "white",
    borderRadius: "2px",
    p: 0,
  }

  return (
    <Box sx={{ position: "relative", ...p.sxProps }}>
      {!p.boundingBox && (
        <IconButton sx={buttonSx} onClick={p.clickButton} data-testid="openRefineMap">
          <OpenInNewIcon />
        </IconButton>
      )}
      {p.boundingBox && (
        <IconButton sx={buttonSx} onClick={p.closeButtonClick}>
          <ClearIcon />
        </IconButton>
      )}
      <div id={p.containerId} style={{ width: "100%", height: "100%", borderRadius: "4px" }} />
    </Box>
  )
}

export default React.memo(MinimapWithClick)
