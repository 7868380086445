import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField } from "@mui/material"
import { MuiChipsInput } from "mui-chips-input"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { type MainInfo } from "../../utils/api/requests.types"
import { useResourceAccess } from "../../utils/auth/userHooks"
import { useUpdateResourceMetadataMutation } from "../../utils/redux/queries/dataset.queries"
import { StyledButton } from "../StyledButton"
import { StyledLoadingButton } from "../StyledLoadingButton"
import DeleteConfirmInput from "./components/DeleteConfirmInput"

type Props = {
  resourceUuid: string
  open: boolean
  closeFn: () => void
  mainInfo: MainInfo
  type: "Dataset" | "DataCollection"
  deleteRedirectUrl?: string
}

const EditResourceModal = (p: Props) => {
  const [showDeleteInput, setShowDeleteInput] = useState(false)
  const [updateResource, { isLoading }] = useUpdateResourceMetadataMutation()
  const form = useForm({
    defaultValues: {
      name: p.mainInfo.name,
      description: p.mainInfo.description,
      tags: p.mainInfo.tags || [],
    },
  })

  const { isAdmin } = useResourceAccess(p.resourceUuid)

  const [tagText, setTagText] = React.useState("")

  const updateClick = async () => {
    if (tagText) {
      form.setValue("tags", [...(form.getValues().tags ?? []), tagText])
      setTagText("")
    }

    await updateResource({
      resourceId: p.resourceUuid,
      update: form.getValues(),
    })

    p.closeFn()
  }

  const descriptionRules = p.type === "DataCollection" ? { required: "This field is required" } : undefined

  const deleteClick = async () => {
    setShowDeleteInput(true)
  }

  return (
    <Dialog
      open={p.open}
      onClose={p.closeFn}
      fullWidth
      maxWidth="md"
      PaperProps={{ sx: { p: 3 } }}
      data-testid="editResourceModal">
      <DialogTitle fontWeight={700}>{`Edit ${p.type === "Dataset" ? "Dataset" : "Data Collection"}`}</DialogTitle>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={form.handleSubmit(updateClick)}>
        <DialogContent sx={{ justifyContent: "center" }}>
          <FormControl sx={{ display: "flex" }}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Controller
                  name="name"
                  control={form.control}
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={"Name"}
                      fullWidth
                      error={!!form.formState.errors.name}
                      helperText={form.formState.errors.name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="description"
                  control={form.control}
                  rules={descriptionRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      multiline
                      rows={4}
                      error={!!form.formState.errors.description}
                      helperText={form.formState.errors.description?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="tags"
                  control={form.control}
                  render={({ field }) => (
                    <MuiChipsInput
                      {...field}
                      label="Keywords"
                      placeholder={!form.getValues().tags.length ? "Keywords (e.g. temperature, salinity)" : ""}
                      fullWidth
                      addOnWhichKey={["Enter", ","]}
                      helperText={"Add keywords (separate with commas)."}
                      inputValue={tagText}
                      onInputChange={value => setTagText(value)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </FormControl>
          <DeleteConfirmInput
            show={showDeleteInput}
            resourceUuid={p.resourceUuid}
            type={p.type}
            redirectUrl={p.deleteRedirectUrl}
          />
        </DialogContent>

        <DialogActions sx={{ justifyContent: "flex-start", pl: 3 }}>
          <StyledLoadingButton variant="contained" type="submit" loading={isLoading}>
            Save
          </StyledLoadingButton>
          {isAdmin && (
            <StyledButton onClick={deleteClick} sx={{ ml: 2 }}>
              Delete
            </StyledButton>
          )}
          <StyledButton variant="text" onClick={p.closeFn} sx={{ ml: 2 }}>
            Cancel
          </StyledButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default React.memo(EditResourceModal)
